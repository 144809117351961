import {
  FormControl,
  FormLabel,
  Stack,
  Button,
  Heading,
  Spinner,
  Checkbox,
  Flex,
  Text,
  Link,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { SessionCtx } from "../context/SessionContext";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../components/passwordInput";
import Input from "../components/Input";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../components/logo.css";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const Register = () => {
  const { t } = useTranslation();

  const requiredMsg = t("register:required");
  const wrongEmailMsg = t("register:wrong_email");
  const notMatchingMsg = t("register:not_match");
  const mustAgreeMsg = t("register:must_agree");

  const RegisterSchema = Yup.object().shape({
    first_name: Yup.string().required(requiredMsg),
    last_name: Yup.string().required(requiredMsg),
    email: Yup.string().email(wrongEmailMsg).required(requiredMsg),
    password: Yup.string().required(requiredMsg),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), undefined], notMatchingMsg)
      .required(requiredMsg),
    affiliation: Yup.string(),
    privacy_policy: Yup.boolean().oneOf([true], mustAgreeMsg),
  });

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);

    ReactGA.event({
      category: "user",
      action: "zapoceti_registrace",
    });
  }, []);

  const { login } = useContext(SessionCtx);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const ref = localStorage.getItem("hodlbot_ref");

  return (
    <Flex
      bg="island"
      w="full"
      minHeight="100vh"
      p={4}
      justifyContent="space-between"
    >
      <Flex flexDir="column" w="full">
        <Flex
          alignItems="center"
          onClick={() => navigate("/")}
          width={150}
          cursor="pointer"
        >
          <Flex overflow="hidden">
            <img
              src="HodlbotLogo.svg"
              alt="Hodl Logo"
              style={{
                width: 38,
                height: 34,
              }}
            />
          </Flex>
          <Text
            ml={2}
            fontSize="xl"
            variant="brand"
            textTransform="uppercase"
            fontWeight={600}
          >
            Hodlbot
          </Text>
        </Flex>
        <Flex h="full" alignItems="center" justifyContent="center">
          <Stack spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6} w="full">
            <Stack>
              <Heading color="white" fontSize={"4xl"}>
                {t("register:title")}
              </Heading>

              <Text color="white" fontSize={"lg"}>
                {t("register:text_1")}
              </Text>
            </Stack>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                password: "",
                confirm_password: "",
                affiliation: ref ?? "",
                privacy_policy: false,
              }}
              validationSchema={RegisterSchema}
              onSubmit={async (values) => {
                setIsLoading(true);
                try {
                  // We cant use directus sdk here because it is buggy, "refresh_token" is missing in the response
                  // await directus.users.createOne

                  const res = await fetch(
                    "https://directus.botcalculator.com/users",
                    {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        first_name: values.first_name,
                        last_name: values.last_name,
                        email: values.email,
                        password: values.password,
                        affiliation: values.affiliation,
                      }),
                    }
                  );

                  const json = await res.json();
                  let email_already_exists = false;

                  if (json?.errors?.length > 0) {
                    json.errors.forEach((error: any) => {
                      if (
                        error.extensions.code === "RECORD_NOT_UNIQUE" &&
                        error.extensions.field === "email"
                      ) {
                        email_already_exists = true;
                      }
                    });

                    if (email_already_exists) {
                      toast(t("register:errors:1"), {
                        type: "error",
                      });
                    } else {
                      toast(t("register:errors:2"), {
                        type: "error",
                      });
                    }
                  } else {
                    localStorage.removeItem("hodlbot_ref");
                    await login(values.email, values.password);

                    ReactGA.event({
                      category: "user",
                      action: "dokonceni_registrace",
                    });

                    toast(t("register:success"), { type: "success" });
                    navigate("/");
                  }
                } catch (e) {
                  const errMsg = (e as any)?.message ?? t("register:errors:2");
                  toast(errMsg, { type: "error" });
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              {({ errors, touched, handleChange, values }) => (
                <Form>
                  <Stack spacing={4}>
                    <FormControl>
                      <FormLabel htmlFor="first_name">
                        {t("register:text_2")}
                      </FormLabel>
                      <Input
                        id="first_name"
                        name="first_name"
                        type="string"
                        onChange={handleChange}
                        value={values.first_name}
                        placeholder={t("register:text_3") ?? ""}
                      />
                      {errors.first_name && touched.first_name ? (
                        <Text color="#9E0038">{errors.first_name}</Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="last_name">
                        {t("register:text_4")}
                      </FormLabel>
                      <Input
                        id="last_name"
                        name="last_name"
                        type="string"
                        onChange={handleChange}
                        value={values.last_name}
                        placeholder={t("register:text_5") ?? ""}
                      />
                      {errors.last_name && touched.last_name ? (
                        <Text color="#9E0038">{errors.last_name}</Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="email">
                        {t("register:text_6")}
                      </FormLabel>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={values.email}
                        placeholder={t("register:text_7") ?? ""}
                      />
                      {errors.email && touched.email ? (
                        <Text color="#9E0038">{errors.email}</Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="password">
                        {t("register:text_8")}
                      </FormLabel>
                      <PasswordInput
                        id="password"
                        onChange={handleChange}
                        value={values.password}
                        placeholder={t("register:text_9") ?? ""}
                      />
                      {errors.password && touched.password ? (
                        <Text color="#9E0038">{errors.password}</Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor="confirm_password">
                        {t("register:text_10")}
                      </FormLabel>
                      <PasswordInput
                        id="confirm_password"
                        onChange={handleChange}
                        value={values.confirm_password}
                        placeholder={t("register:text_11") ?? ""}
                      />
                      {errors.confirm_password && touched.confirm_password ? (
                        <Text color="#9E0038">{errors.confirm_password}</Text>
                      ) : null}
                    </FormControl>

                    <FormControl display={ref === null ? "block" : "none"}>
                      <FormLabel htmlFor="affiliation">
                        {t("register:text_12")}
                      </FormLabel>
                      <Input
                        id="affiliation"
                        name="affiliation"
                        type="string"
                        onChange={handleChange}
                        value={values.affiliation ?? ""}
                      />
                      {errors.affiliation && touched.affiliation ? (
                        <Text color="#9E0038">{errors.affiliation}</Text>
                      ) : null}
                    </FormControl>
                    <Stack spacing={10}>
                      <Stack
                        direction={{ base: "column", sm: "row" }}
                        align={"start"}
                        justify={"space-between"}
                      >
                        <Flex flexDir="column">
                          <Checkbox
                            id="privacy_policy"
                            name="privacy_policy"
                            color="white"
                            onChange={handleChange}
                            isChecked={values.privacy_policy}
                          >
                            {t("register:text_13:part_1")}{" "}
                            <Link
                              variant="brand"
                              as={RouterLink}
                              to="/general_terms"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t("register:text_13:part_2")}
                            </Link>
                          </Checkbox>
                          {errors.privacy_policy && touched.privacy_policy ? (
                            <Text color="#9E0038">{errors.privacy_policy}</Text>
                          ) : null}
                        </Flex>
                      </Stack>
                      <Button
                        bg="brand"
                        color="island"
                        fontSize={14}
                        type="submit"
                      >
                        {isLoading ? <Spinner /> : t("register:button_1")}
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Flex>

        <Text color="white" fontSize={12}>
          © 2023 Hodlbot
        </Text>
      </Flex>

      <Flex display={{ base: "none", md: "flex", lg: "contents" }}>
        <img src="login.png" />
      </Flex>
    </Flex>
  );
};

export default Register;
