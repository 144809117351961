import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { cs } from "./cs";
import { en } from "./en";
import ReactGA from "react-ga4";

export const HODLBOT_LANGUAGE_KEY = "HODLBOT_LANGUAGE_KEY";

const resources = {
  cs,
  en,
};

i18n.use(initReactI18next).init(
  {
    resources,
    fallbackLng: {
      default: ["cs", "en"],
    },
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
  },
  () => {
    const storedLanguage = localStorage.getItem(HODLBOT_LANGUAGE_KEY);
    console.log(storedLanguage);

    if (storedLanguage === null) {
      if (["cs", "cs-CZ", "sk", "sk-SK"].includes(i18n.language)) {
        i18n.changeLanguage("cs");
        localStorage.setItem(HODLBOT_LANGUAGE_KEY, "cs");
      } else {
        i18n.changeLanguage("en");
        localStorage.setItem(HODLBOT_LANGUAGE_KEY, "en");
      }
    } else {
      i18n.changeLanguage(storedLanguage);
    }
  }
);

const supportedLanguages = Object.keys(resources);

export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
  localStorage.setItem(HODLBOT_LANGUAGE_KEY, lng);

  ReactGA.event({
    category: "action",
    action: "language_switch",
    label: lng,
  });
};

export { supportedLanguages };
