import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useEffect } from "react";

import Island from "../components/Island";

const PrivacyPolicy = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      <Island>
        <Text fontSize="3xl">Zásady zpracování os. údajů</Text>
        <Text mb={4} lineHeight="1.8">
          <Text fontSize="xl" my={6}>
            Úvodní informace
          </Text>
          Společnost s ručeným omezením Algo Traders s.r.o., IČO: 19194536, se
          sídlem: Hoštičky 39, 33901 Klatovy, (dále jen „Společnost"), tímto
          poskytuje informace o způsobu a rozsahu zpracování osobních údajů
          svých klientů (dále jen „Klient"), včetně práv Klientů souvisejících
          se zpracováním osobních údajů (dále jen „Zásady"), a to v souladu se
          zákonem č. 110/2019 Sb., o zpracování osobních údajů a Nařízením (EU)
          2016/679.
          <Text fontSize="xl" my={6}>
            Správce osobních údajů a způsob zpracování údajů
          </Text>
          Společnost, jakožto správce osobních údajů, shromažďuje, uchovává a
          využívá osobní údaje ve spojení se svojí podnikatelskou činností a za
          účelem jejich řádné správy byly vytvořeny tyto Zásady.
          <br /> <br />
          Zpracování a uchovávání osobních údajů probíhá v sídle Společnosti a
          je prováděno výlučně zaměstnanci Společnosti, nestanoví-li tyto Zásady
          jinak. Zpracování osobních údajů probíhá manuálně i automatizovaně v
          elektronických informačních systémech, a to v elektronické podobě,
          vždy při vysokém technickém, organizačním a personálním zabezpečení ve
          smyslu požadavků příslušné legislativy.
          <br /> <br />
          Pakliže Klient využije služby Společnosti, kontaktní údaje Klienta
          může Společnost využít k tomu, aby Klientovi zasílala obchodní sdělení
          o službách, které poskytuje a které by Klienta mohly zajímat. Z
          odebírání obchodních sdělení se Klient může kdykoliv odhlásit zasláním
          zprávy na info@hodlbot.cz{" "}
          <Text fontSize="xl" my={6}>
            Účel zpracování osobních údajů
          </Text>
          Společnost působí při zpracování osobních údajů jako správce a určuje
          tak, za jakým účelem a jakými prostředky bude docházet ke zpracování
          osobních údajů. Osobní údaje jsou zpracovávány buď: (i) bez souhlasu
          Klienta na základě plnění smlouvy, oprávněného zájmu Společnosti nebo
          z důvodu plnění právní povinnosti, nebo (ii) na základě souhlasu
          Klienta. Společnost je oprávněna zpracovávat osobní údaje zejména pro
          následující účely
          <Text fontSize="xl" my={6}>
            Zpracování bez souhlasu Klienta
          </Text>
          Plnění právních povinností - povinnosti ve smyslu účetní a daňové
          legislativy, archivační povinnost. Plněním právních povinností se
          rozumí také poskytování údajů a informací orgánům činným v trestním
          řízení, případně dalším orgánům veřejné moci v souladu s příslušnými
          právními předpisy. Plnění právních povinností se dále rozumí
          regulatorní povinnosti Společnosti zejména dle následujících předpisů:
          <br /> <br />
          zákon č. 634/1992 Sb., o ochraně spotřebitele (tento zákon upravuje
          práva spotřebitele)
          <Text fontSize="xl" my={6}>
            Zpracování se souhlasem Klienta
          </Text>
          Zasílání a sdělování obchodních sdělení a marketingová činnost -
          nabízení služeb Společnosti a její propagace vůči stávajícím i
          potenciálním Klientům, předávání údajů třetím stranám pro zobrazení
          nabídek na dalších webech.
          <br /> <br />
          Soubory cookies - cookies jsou textové soubory obsahující krátké
          údaje, které mohou být při návštěvě webové stránky uloženy do zařízení
          Klienta. Na webových stránkách Společnosti jsou soubory cookies
          používány k následujícím účelům: (i) funkční - vyžadovány pro základní
          fungování stránek. (ii) zvýšení komfortu - slouží pro zlepšování
          funkcionalit webu, (iii) sociální média a personalizace zobrazování
          reklam - možnost propojení se sociálními sítěmi a přizpůsobování
          reklamy uživatelské historii Klienta.
          <Text fontSize="xl" my={6}>
            Rozsah zpracovaných údajů
          </Text>
          Společnost je oprávněna zpracovávat následující osobní údaje, které
          získává od Klientů v souvislosti s jednáním o uzavření smlouvy a v
          souvislosti s plněním smlouvy, dle níže uvedených účelů zpracování.
          Osobní údaje Klienta
          <UnorderedList ml={12} mt={4}>
            <ListItem> E-mail </ListItem>
            <ListItem>Plnění smluvního vztahu </ListItem>
            <ListItem>
              Údaje o využívání služeb Společnosti, transakční údaje a údaje ze
              vzájemné komunikace
            </ListItem>
            <ListItem>Údaje o chování Klientů na webu </ListItem>
            <ListItem>
              Zasílání a sdělování obchodních sdělení (pokud Klient udělil
              souhlas), Soubory cookies.
            </ListItem>
            <ListItem> Údaje o smlouvě a jejím plnění. </ListItem>
            <ListItem> Přístup k osobním údajům</ListItem>
          </UnorderedList>
          <br /> <br />
          Osobní údaje jsou zpřístupněny pouze oprávněným zaměstnancům
          Společnosti, či jednotlivým zpracovatelům osobních údajů, a to pouze v
          míře nezbytně nutné pro naplnění jednotlivých účelů zpracování,
          případně na základě souhlasu Klientů se zpracováním osobních údajů.
          <Text fontSize="xl" my={6}>
            Zpracovatelé osobních údajů
          </Text>
          Společnost dále jako správce pověřuje zpracováním osobních údajů další
          subjekty, jako tzv. zpracovatele. Zpracovatelem osobních údajů je
          subjekt, který pro Společnost zpracovává osobní údaje, pro Společností
          určený účely, přičemž ochranu osobních údajů Společnost zajišťuje
          smluvně tak, aby byla garantováno technické i organizační zabezpečení
          údajů Klientů a nemohlo tak dojít k jejich zneužití. Všichni
          zpracovatelé jsou vázání smluvní mlčenlivostí a nesmějí údaje Klientů
          používat k jiným účelům, než ke kterým jim je Společnost
          zpřístupňujeme.
          <br /> <br />
          Se souhlasem Klienta Společnost údaje předává také reklamním a
          sociálním sítím pro zobrazení reklamy na dalších webech.
          <br /> <br />
          Zpracovateli osobních údajů jsou zpracovatelé, kteří Společnosti
          poskytují serverové, webové, cloudové nebo IT služby, či kteří jsou
          obchodními partnery Společnosti.
          <br /> <br />
          Klient, jakožto subjekt údajů, má v souvislosti se zpracováním
          osobních údajů práva, která vyplývají z právních předpisů a která může
          kdykoliv uplatnit. Jedná se o následující práva:
          <br /> <br />
          Právo na informace a přístup k osobním údajům: chce-li Klient vědět,
          zda Společnost zpracovává osobní údaje, má právo získat informace o
          tom, jestli jsou jeho osobní údaje zpracovávány, a pokud tomu tak je,
          má právo k těmto osobním údajům získat přístup.
          <br /> <br />
          Právo na opravu: v případě, že Klient dojde k závěru, že Společnost
          zpracovává nepřesné či neúplné osobní údaje, má právo požadovat jejich
          opravu a doplnění. Společnost provede opravu či doplnění údajů bez
          zbytečného odkladu.
          <br /> <br />
          Právo na výmaz: v případě, že Klient požádá o výmaz, Společnost vymaže
          jeho osobní údaje pokud (i) již nejsou potřebné pro účely, pro které
          byly shromážděny nebo jinak zpracovány, (ii) zpracování je
          protiprávní, (iii) Klient vznese námitky proti zpracování a neexistují
          žádné převažující oprávněné důvody pro zpracování jeho osobních údajů.
          <br /> <br />
          Právo na omezení zpracování osobních údajů: v případě, že Klient
          požádá o omezení zpracování, Společnost osobní údaje znepřístupní,
          dočasně odstraní či uchová anebo provede jiné úkony zpracování, které
          budou potřebné pro řádný výkon uplatněného práva.
          <br /> <br />
          Právo na přenositelnost údajů: v případě, že o to Klient požádá,
          osobní údaje, které se ho týkají a které předal Společnosti, mu budou
          předány ve strukturovaném, běžně používaném a strojově čitelném
          formátu.
          <br /> <br />
          Právo vznést námitku: právo vznést námitku proti zpracování osobních
          údajů.
          <br /> <br />
          Právo obrátit se na dozorový úřad: Klient má právo kontaktovat Úřad
          pro ochranu osobních údajů, adresa: Pplk. Sochora 27, 170 00, Praha 7,
          a podat stížnost týkající se ochrany údajů a soukromí a související s
          činností Společnosti.
          <br /> <br />
          Právo odvolat souhlas: v případě, že Klient poskytl souhlas se
          zpracováním osobním údajů pro účely, které vyžadují souhlas, má právo
          kdykoli tento souhlas odvolat.
          <Text fontSize="xl" my={6}>
            Doba zpracování údajů
          </Text>
          Společnost zpracovává osobní údaje za účelem plnění právních
          povinností po celou dobu trvání smlouvy a po dobu 10 let po jejím
          ukončení smlouvy. Obchodní sdělení jsou zasílána Klientům do doby
          zrušení jejich odběru nebo odvolání jejich souhlasu. Další zpracování
          osobních údajů nad rámec uvedených lhůt provádí Společnost pouze v
          případě, že je to nezbytné pro účely oprávněných zájmů Společnosti
          nebo pro splnění povinností vyplývajících z právních předpisů, a to po
          dobu nezbytně nutnou.
          <br /> <br />
          Osobní údaje, které Společnost zpracovává na základě souhlasu Klienta
          bude zpracovávat do doby, než Klient souhlas odvolá. Pro odvolání
          souhlasu je nutné zaslat e-mail s příslušnou žádostí na
          info@hodlbot.cz
          <Text fontSize="xl" my={6}>
            Zabezpečení údajů
          </Text>
          Společnost klade při zpracování osobních údajů důraz na technické i
          organizační zabezpečení zpracovávaných údajů a za tímto účelem přijalo
          odpovídající opatření a vytvořila systém interních pravidel. Veškeré
          osobní údaje v elektronické formě jsou uloženy v databázích a
          informačních systémech, k nimž mají přístup pouze osoby, které
          potřebují s osobními údaji bezprostředně nakládat pro účely uvedené v
          těchto Zásadách, a to pouze v nezbytném rozsahu.
          <Text fontSize="xl" my={6}>
            Závěrečné informace
          </Text>
          Tyto Zásady jsou účinné od 1. 5. 2023. Jakékoliv dotazy a požadavky ve
          vztahu ke zpracování osobních údajů s námi můžete konzultovat
          prostřednictvím e-mailové adresy: info@hodlbot.cz
        </Text>
      </Island>
    </>
  );
};

export default PrivacyPolicy;
