import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navbar";

import About from "./pages/About";
import Login from "./pages/Login";
import HodlXHodlBot from "./pages/Hodl_x_Hodl_bot";

import "./trans/i18n";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import ReactGA from "react-ga4";

import "./utils/index.css";

import "@fontsource/ibm-plex-sans";

import GeneralTerms from "./pages/GeneralTerms";
import Contacts from "./pages/Contatcts";
import Statistics from "./pages/Statistics";
import Backtest from "./pages/Backtest";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Calculator from "./pages/Calculator";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Secured from "./pages/Secured";
import MyStatistics from "./pages/MyStatistics";
import ApiKeys from "./pages/ApiKeys";
import PortfolioDetail from "./pages/PortfolioDetail";
import Affil from "./pages/Affil";
import AffilTerms from "./pages/AffilTerms";
import { useTranslation } from "react-i18next";
import GeneralTermsEn from "./pages/GeneralTermsEn";
import PrivacyPolicyEn from "./pages/PrivacyPolicyEn";
import { changeLanguage } from "i18next";
import ArticlePage from "./pages/ArticlePage";
import Dca from "./pages/Dca";
import DcaDetail from "./pages/DcaDetail";
import DcaCreate from "./pages/DcaCreate";

const App = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (location?.search.includes("?lang=")) {
      const lang = location.search.split("?lang=")[1];
      changeLanguage(lang);

      ReactGA.event({
        category: "action",
        action: "language_load_from_url",
        label: lang,
      });
    }

    if (location.hash.includes("ref=")) {
      localStorage.setItem("hodlbot_ref", location.hash.split("ref=")[1]);
    }
  }, [i18n.language, location, location.hash]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/" + i18n.language + location.pathname + location.hash,
      title: "/" + i18n.language + location.pathname + location.hash,
    });

    if (location.search) {
      ReactGA.event({
        category: "Link click",
        action: location.search,
      });
    }
  }, [i18n.language, location]);

  return (
    <>
      <Navbar>
        <Routes>
          <Route path="/" element={<About />} />
          <Route
            path="/profile"
            element={
              <Secured>
                <Profile />
              </Secured>
            }
          />
          <Route path="my_statistics">
            <Route
              index
              element={
                <Secured>
                  <MyStatistics />
                </Secured>
              }
            />
            <Route
              path=":id"
              element={
                <Secured>
                  <PortfolioDetail />
                </Secured>
              }
            />
          </Route>
          <Route path="dca">
            <Route
              index
              element={
                <Secured>
                  <Dca />
                </Secured>
              }
            />
            <Route path=":id">
              <Route
                index
                element={
                  <Secured>
                    <DcaDetail />
                  </Secured>
                }
              />
              <Route
                path="edit"
                element={
                  <Secured>
                    <DcaCreate />
                  </Secured>
                }
              />
            </Route>
            <Route
              path="create"
              element={
                <Secured>
                  <DcaCreate />
                </Secured>
              }
            />
          </Route>
          <Route
            path="/api_keys"
            element={
              <Secured>
                <ApiKeys />
              </Secured>
            }
          />
          <Route
            path="/affiliate"
            element={
              <Secured>
                <Affil />
              </Secured>
            }
          />
          <Route
            path="/affiliate-terms"
            element={
              <Secured>
                <AffilTerms />
              </Secured>
            }
          />
          <Route path="/signin" element={<Login />} />
          <Route path="/signup" element={<Register />} />
          <Route
            path="/general_terms"
            element={
              i18n.language === "cs" ? <GeneralTerms /> : <GeneralTermsEn />
            }
          />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/backtest" element={<Backtest />} />
          <Route path="/calculator" element={<Calculator displayBanner />} />
          <Route element={<ArticlePage />} path="articles/:articleId" />
          <Route
            path="/privacy_policy"
            element={
              i18n.language === "cs" ? <PrivacyPolicy /> : <PrivacyPolicyEn />
            }
          />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/admin">
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route path="/hodl_x_hodlBot" element={<HodlXHodlBot />} />
        </Routes>
        <ToastContainer style={{ zIndex: 999999999 }} />
      </Navbar>
    </>
  );
};

export default App;
