import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { IoLogoDiscord } from 'react-icons/io5';
import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import Island from '../components/Island';

import { ReactComponent as MailIcon } from '../icons/mail.svg';
import { ReactComponent as AccountCirlceIcon } from '../icons/account_circle.svg';
import Input from '../components/Input';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsArrowRight } from 'react-icons/bs';

const Contacts = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      message: '',
    },
    onSubmit: async (values) => {
      try {
        await fetch('https://api.botcalculator.com/send_email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: values.email,
            name: values.name,
            content: values.message,
          }),
        });
        toast('Zpráva byla odeslána', { type: 'success' });
      } catch (error) {
        toast('Zprávu se nezdařilo odeslat', { type: 'error' });
      }
    },
  });

  return (
    <>
      <Island>
        <Flex w='full'>
          <Text fontSize='3xl' mb={8} align='left' w='full'>
            {t('contacts:title')}
          </Text>
          <a
            href='https://calendly.com/hodlbot/30min'
            target='_blank'
            rel='noreferrer'
          >
            <Button bg='white' color='island' fontSize={16} fontWeight={400}>
              {t('online_meeting:title')}
              <Icon ml={2} as={BsArrowRight} />
            </Button>
          </a>
        </Flex>

        <Flex
          flexDir='column'
          bg='bg'
          borderRadius='lg'
          w='full'
          p={8}
          border='1px solid #6E7191 '
        >
          <form onSubmit={formik.handleSubmit}>
            <FormControl>
              <FormLabel color='#D9DBE9' htmlFor='name'>
                {t('contacts:text_1')}
              </FormLabel>
              <Input
                id='name'
                name='name'
                placeholder='John Doe'
                bg='island'
                onChange={formik.handleChange}
                value={formik.values.name}
                iconLeft={<AccountCirlceIcon fill='white' />}
              />
            </FormControl>
            <FormControl my={2}>
              <FormLabel htmlFor='email'> {t('contacts:text_2')}</FormLabel>
              <Input
                id='email'
                name='email'
                type='email'
                placeholder='johndoe@email.com'
                bg='island'
                onChange={formik.handleChange}
                value={formik.values.email}
                iconLeft={<MailIcon fill='white' />}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='message'> {t('contacts:text_3')}</FormLabel>
              <Textarea
                id='message'
                name='message'
                onChange={formik.handleChange}
                value={formik.values.message}
                bg='island'
                required
                borderColor='#6E7191'
              />
            </FormControl>
            <Button
              mt={4}
              bg='brand'
              color='island'
              type='submit'
              fontSize={14}
              fontWeight={500}
            >
              {t('contacts:text_4')}
            </Button>
          </form>
        </Flex>
      </Island>
      <Island>
        <Text fontSize='3xl' mb={8}>
          {t('contacts:title_2')}
        </Text>
        <Text align='center' mb={4}>
          {t('contacts:text_5')}
          <br /> {t('contacts:text_6')}
        </Text>
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          width='full'
          justifyContent='space-evenly'
        >
          <Flex
            bg='#404EED40'
            width='full'
            justifyContent='center'
            alignItems='center'
            p={8}
            m={2}
            borderRadius='lg'
            cursor='pointer'
            onClick={() =>
              window.open('https://discord.com/invite/YpfMSR2Yp2', '_blank')
            }
          >
            <Flex
              bg='#404EED'
              borderRadius='lg'
              p={2}
              cursor='pointer'
              onClick={() =>
                window.open('https://discord.com/invite/YpfMSR2Yp2', '_blank')
              }
            >
              <Icon m={1} fontSize='4xl' as={IoLogoDiscord} />
            </Flex>
          </Flex>
          <Flex
            bg='#F0007340'
            width='full'
            justifyContent='center'
            alignItems='center'
            p={8}
            m={2}
            borderRadius='lg'
            cursor='pointer'
            onClick={() =>
              window.open('https://www.instagram.com/hodl.bot/', '_blank')
            }
          >
            <Flex
              bg='#F00073'
              borderRadius='lg'
              p={2}
              cursor='pointer'
              onClick={() =>
                window.open('https://www.instagram.com/hodl.bot/', '_blank')
              }
            >
              <Icon m={1} fontSize='4xl' as={AiOutlineInstagram} />
            </Flex>
          </Flex>
          <Flex
            bg='#1877F240'
            width='full'
            justifyContent='center'
            alignItems='center'
            p={8}
            m={2}
            borderRadius='lg'
            cursor='pointer'
            onClick={() =>
              window.open('https://twitter.com/hodlbotcz', '_blank')
            }
          >
            <Flex
              bg='#1877F2'
              borderRadius='lg'
              p={2}
              cursor='pointer'
              onClick={() =>
                window.open('https://twitter.com/hodlbotcz', '_blank')
              }
            >
              <Icon m={1} fontSize='4xl' as={AiOutlineTwitter} />
            </Flex>
          </Flex>
        </Flex>
      </Island>
      <Island>
        <Text fontSize='3xl' mb={8}>
          {t('contacts:title_3')}
        </Text>
        <Flex flexDir='column' alignItems='center'>
          <Flex>
            <MailIcon fill='white' />
            <Text ml={2}>info@hodlbot.cz</Text>
          </Flex>
          <Flex my={2}>
            <Text ml={2}>Algo traders s.r.o</Text>
          </Flex>
          <Flex>
            <Text>IČO: 4569498</Text>
          </Flex>
        </Flex>
      </Island>
    </>
  );
};

export default Contacts;
