import { ListItem, OrderedList, Link, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import Island from "../components/Island";

const GeneralTermsEn = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      <Island>
        <Text fontSize="3xl">General Terms</Text>
        <Text mb={4} lineHeight="1.8">
          <Text fontSize="xl" my={6}>
            1. Rules and Conditions
          </Text>
          a. hodlbot.cz does not provide investment advice, financial advice, or
          investment recommendations.
          <br />
          b. We do not offer any analyses or recommendations concerning the
          purchase of investment instruments or the possibility of trading in
          financial markets in the short or long term.
          <br />
          c. All information is a presentation of the software's historical
          performance and is not investment advice.
          <br />
          d. Trading in financial markets is risky and carries the possibility
          of losing part or all of your investment capital. Presented past
          results are not a guarantee of future results and do not reflect the
          reality of trading on the stock exchange.
          <Text fontSize="xl" my={6}>
            2. Introduction and Basic Terms
          </Text>
          a. "Provider": Algo Traders s.r.o., ID: 19194536
          <br />
          b. "Client": User who uses the product (the product is only software
          rental) presented on the websites hodlbot.cz, botcalculator.com, and
          stats.botcalculator.com.
          <br />
          c. "Cryptocurrency": A virtual asset as defined by law.
          <br />
          d. "DCA" (Dollar-Cost Averaging): An investment strategy where the
          investor divides the total amount they wish to invest over a given
          period into smaller periodic purchases of a given asset, thereby
          reducing the impact of volatility on the overall purchase.
          <br />
          e. "Hodlbot": Software operated by the Algo Traders s.r.o. team.
          Presented on hodlbot.cz.
          <br />
          f. "API Key": A set of unique strings allowing secure communication
          between applications. For example, communication between the
          application and the exchange or exchange office for the purpose of
          buying cryptocurrencies and finding out account balances.
          <br />
          g. "Limit Order": A limit order specifies the price at which the
          client is willing to buy or sell cryptocurrency.
          <br />
          h. "Market Order": An order to buy or sell cryptocurrency at the
          current price on a given exchange or exchange office.
          <br />
          i. "Trading Pair": Specifies which assets the bot will trade (usually
          it is cryptocurrency and fiat in stablecoin, for example, BTC/USD).
          <br />
          j. "Capital": The total value of allocated funds (asset + fiat)
          expressed in fiat at the time of starting the bot instance.
          <br />
          k. "Price Unit": One of the variable values of the reference table,
          which defines the value of each transaction made by the bot.
          <br />
          l. "Min Buy": One of the extremes, it is the value under which, when
          the client's multiplied asset reaches, the bot will not have the funds
          for further purchase. (All capital will be allocated in the multiplied
          asset and the fiat balance will be smaller than the priceUnit).
          <br />
          m. "Max Sell": The second of the extremes, it is the value above
          which, when the client's multiplied asset reaches, the bot will not
          have enough assets for further sale. (All capital will be allocated in
          fiat and the rest of the asset will be smaller than the priceUnit at
          that price - priceUnit/MaxSell).
          <br />
          n. "Double Trade": Just a name for a pair trade of purchase and sale,
          where a surplus in cryptocurrency arises from this pair and that is
          the profit.
          <br />
          o. "Crypto Profit": It is the numerical value of the client's asset,
          which you accumulate for one closed double trade. "Crypto and fiat
          balance": expresses the worst possible capital status at a given price
          per unit of asset being traded by the bot (crypto + fiat).
          <Text fontSize="xl" my={6}>
            3. Business, Delivery, and Payment Conditions
          </Text>
          a. The provider is the operator of the websites mentioned in point
          2b).
          <br />
          b. Hodlbot serves clients to automate cryptocurrency purchases using
          the strategy through exchanges and exchange offices, which the client
          has chosen himself. These terms and conditions (hereinafter referred
          to as "Terms and Conditions") represent the framework agreement that
          will be concluded between the Client and the Provider and which
          regulates the rights and obligations of the parties when providing
          services by the Provider to the Client (hereinafter referred to as the
          "Framework Agreement"). The Framework Agreement is concluded at the
          moment when the Client approves the wording of these Terms and
          Conditions as part of the sending of the configuration table.
          <br />
          c. The process of buying cryptocurrency using a bot:
          <OrderedList ml={12} mt={4}>
            <ListItem>
              The client connects the exchange or currency exchange with the
              application.
            </ListItem>
            <ListItem>
              Registers on the currency exchange through a web or mobile
              application. Here, they also agree to the terms and conditions of
              the chosen exchange. If there is any issue with the
              availability/liquidity of their capital, the chosen exchange is
              fully responsible for it. Algo Traders s.r.o. does not have access
              to the capital, meaning deposits, withdrawals, and holding of
              clients' capital. This is not possible even from a technical
              standpoint. Algo Traders s.r.o. only rents software.
            </ListItem>
            <ListItem>
              The client links their account on the exchange or currency
              exchange with the application by generating an API key on the
              given exchange or currency exchange.
            </ListItem>
            <ListItem>
              The client sends the generated keys to Algo Traders s.r.o. The
              client deposits fiat and assets in the ratio defined in the
              reference table on the connected exchange or currency exchange for
              the purpose of buying cryptocurrencies.
            </ListItem>
            <ListItem>
              By sending the reference table, the client sets the buying
              strategy on the specific connected exchange or currency exchange,
              which includes settings like 1. Init price 2. % jump 3. priceUnit
              4. minBuy 5. maxSell 6. email for informing the client about the
              portfolio status.
            </ListItem>
            <ListItem>
              Frequency of regular purchases. We use exclusively limit orders,
              so if a transaction is executed, we can guarantee that it was
              executed under the required or better conditions.
            </ListItem>
            <ListItem>
              Purchased cryptocurrencies will remain on the given exchange or
              currency exchange where the trade was executed through the bot
              connection. The provider cannot handle these funds.
            </ListItem>
            <ListItem>
              The client has full rights and technical access to stop the
              Hodlbot at any time without fees or penalties.
            </ListItem>
          </OrderedList>
          <Text fontSize="xl" my={6}>
            4. Limitation of Liability for Damages
          </Text>
          a. Given the constant changes in the market value of cryptocurrencies,
          the Client acknowledges and understands that the cryptocurrencies they
          purchase may lose value due to market developments and changes
          (especially supply and demand). The Provider is only responsible for
          executing the purchase order according to the Client's instruction
          accepted by the Provider and is not responsible for the change in
          value of the purchased cryptocurrency after the purchase is made.
          <br />
          b. The Provider is not responsible under any circumstances for damages
          incurred on the Client's side and related to:
          <OrderedList ml={12} mt={4}>
            <ListItem>
              fluctuations in the exchange rate of cryptocurrencies
            </ListItem>
            <ListItem>
              failure of data transmission or any other communication channel
              between the Client and the Provider or any other third party,
              unavailability of the Provider's application, or failure of any
              third party in relation to the settlement of the order to purchase
              cryptocurrencies.
            </ListItem>
            <ListItem>
              the Client's delay in fulfilling their obligations under these
              Terms.
            </ListItem>
          </OrderedList>
          <br />
          c. The platform operator is not responsible for losses or damages that
          may arise to the user from using the software, nor for losses or
          damages caused by software errors, website errors, or operational
          failures. The user uses the software at their own risk.
          <br />
          d. In the event of a service outage lasting more than one day, the
          operator is obliged to compensate for the outage by extending the
          subscription. If the user cannot use the service due to an obstacle on
          the part of the operator, it is the operator's obligation to pay the
          user the unused portion of the subscription.
          <Text fontSize="xl" my={6}>
            5. Processing of Personal Data
          </Text>
          a. The Provider processes and stores the Client's personal data in
          accordance with the Regulation of the European Parliament and Council
          (EU) 2016/679, on the protection of natural persons with regard to the
          processing of personal data and on the free movement of such data
          (GDPR), and Act No. 110/2019 Coll., on the processing of personal data
          and related legal regulations.
          <br />
          b. The principles of personal data processing and information on the
          processing of personal data are published on the Provider's website in
          the section on Principles of Personal Data Processing, and the Client
          has familiarized themselves with these and expresses their consent to
          them.
          <br />
          c. Link to the principles of processing
          <Link
            variant="brand"
            as={RouterLink}
            to="/privacy_policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            personal data
          </Link>
          <Text fontSize="xl" my={6}>
            6. Disclaimer
          </Text>
          a. The Provider warns that the content of its website, on which it
          offers services, may contain outdated information, errors, or
          inaccuracies, and reserves the right to make corrections,
          modifications, or updates to the website content, if deemed necessary
          or appropriate.
          <br />
          b. Any information available on the Provider's website does not
          constitute investment, tax, legal, or other advice, nor an offer to
          enter into a contract for the provision of financial, investment, or
          other services, or any other proposal from the Provider to a potential
          Client.
          <br />
          c. The Provider does not provide Clients with any recommendations
          regarding the purchase and sale of cryptocurrencies, investment
          research, or financial analyses and similar services. All information
          provided on the Provider's website is not advisory in nature and aims
          only to freely make such information available to a potential Client.
          <br />
          d. The Provider is not obligated to ensure uninterrupted website
          availability and does not guarantee any minimum website response time
          to Client requests. The operation and functionality of the website may
          be affected by factors beyond the Provider's control, and the Provider
          assumes no responsibility for their consequences.
          <Text fontSize="xl" my={6}>
            7. Changes to Terms and Conditions
          </Text>
          a. The Provider is entitled to unilaterally change the terms and
          conditions, and in accordance with the Regulation of the European
          Parliament and Council (EU) No. 2019/1150, will publish these changes,
          or the new version of the terms and conditions, on its website and
          will inform existing affected users directly within the System or in
          another manner. Affected users confirm their acceptance of such
          changes by their continued use of the hodlbot.cz application from the
          company Algo Traders s.r.o. after the change in terms and conditions.
          <br />
          b. All changes to the website, in the definition of the service, or in
          licensing terms will be announced on the main page in the "Platform
          news" section.
          <Text fontSize="xl" my={6}>
            8. Governing Law and Jurisdiction
          </Text>
          a. Any legal relationships arising from or as a result of using the
          Provider's services will be governed by the laws of the Czech
          Republic.
          <br />
          b. Any disputes arising from or as a result of using the Provider's
          services will be resolved by the competent courts of the Czech
          Republic.
          <br />
          c. The Client acknowledges that the supervisory authority overseeing
          the Provider's compliance with consumer protection obligations is the
          Czech Trade Inspection Authority.
          <Text fontSize="xl" my={6}>
            9. User Rights and Obligations
          </Text>
          a. The service can be used by users aged 18 and above.
          <br />
          b. To use the service, the user is required to own an account set up
          on an exchange with all the verification that the exchange requires.
          <br />
          c. The user is solely responsible for their own losses. Hodlbot.eu is
          not liable for any losses.
          <br />
          d. The user must identify themselves via email.
          <Text fontSize="xl" my={6}>
            10. Prohibited Activities
          </Text>
          a. Misuse of communication channels
          <br />
          b. Sending illegal content to information channels
          <br />
          c. Sending advertisements
          <br />
          d. Exploiting found system vulnerabilities for personal gain without
          prior notification
          <br />
          e. Reselling the operated software to third parties
          <br />
          f. Operating the software for a third party without proper legal
          authorization
          <Text fontSize="xl" my={6}>
            11. Paid Services
          </Text>
          a. All paid services are payable in CZK, EUR, stablecoins, or Bitcoins
          according to the current exchange rate of the Czech National Bank, or
          alternatively the rates on the Binance.com platform. The price is set
          by a subscription fee, which is individually proposed to clients
          depending on their portfolios. Any subscription that the user is
          unable to utilize in full cannot be refunded. Operating the software
          in "pause/waiting" mode is still considered as using the subscription,
          only the software in "stop" mode also pauses the subscription.
          <Text fontSize="xl" my={6}>
            12. Final Information
          </Text>
          a. These Terms are effective from December 1, 2022. Any questions and
          requests related to the processing of personal data can be consulted
          with us via the email address: info@hodlbot.cz
        </Text>
      </Island>
    </>
  );
};

export default GeneralTermsEn;
