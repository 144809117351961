import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  IconButton,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { directus } from "../api/directus";
import { ItemInput, UserItem } from "@directus/sdk";
import Island from "../components/Island";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { SessionCtx } from "../context/SessionContext";
import { CopyIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

function makeid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const Affil = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const [isCreating, setIsCreating] = useState(false);

  const [haveAgreed, setHaveAgreed] = useState(false);

  const { user } = useContext(SessionCtx);
  const [tableData, setTableData] = useState<any[]>([]);

  const fetchMyBilling = async () => {
    console.log(user);

    const myAffilCodes: any = await directus.items("aa_affil").readByQuery({
      filter: {
        owner: {
          _eq: user?.id,
        },
      },
    });

    const token = await directus.auth.token;

    const affilUsersPromise =
      myAffilCodes?.data?.map(
        async (affilCode: any) =>
          await fetch(
            `https://api.botcalculator.com/v1/affiliate?affil=${affilCode.value}`,
            {
              headers: {
                AuthToken: token || "",
                "Content-Type": "application/json",
              },
            }
          ).then((res) => res.json())
      ) ?? [];

    const affilUsers = await Promise.all(affilUsersPromise);

    const data = [];

    for (let i = 0; i < myAffilCodes?.data?.length; i++) {
      data.push({
        code: myAffilCodes?.data?.[i]?.value,
        affilUsers: affilUsers[i].Data,
      });
    }
    setTableData(data);
  };

  useEffect(() => {
    fetchMyBilling();
  }, []);

  return (
    <>
      <Island>
        <Flex flexDir="column" mb={6} w="full">
          <Heading
            color="white"
            fontSize={"4xl"}
            alignContent="center"
            textAlign="center"
          >
            {t("affiliate:title")}
          </Heading>
          <Text mt={8} color="white" fontSize={"lg"}>
            <span style={{ color: "#50C8FC" }}>{t("affiliate:text_1")}</span>
            <br /> <br />
            {t("affiliate:text_2")}
            <br /> <br />
            {t("affiliate:text_3:part_1")}{" "}
            <span style={{ color: "#50C8FC" }}>
              {t("affiliate:text_3:part_2")}
            </span>
            . {t("affiliate:text_3:part_3")}
            <br /> <br />
            {t("affiliate:text_4")}
            <br /> <br />
            {t("affiliate:text_5:part_1")}{" "}
            <span style={{ color: "#50C8FC" }}>
              {" "}
              {t("affiliate:text_5:part_2")}{" "}
            </span>{" "}
            {t("affiliate:text_5:part_3")}
            <br /> <br />
            {t("affiliate:text_6:part_1")}{" "}
            <span
              onClick={() => navigate("/contacts")}
              style={{
                color: "#50C8FC",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {t("affiliate:text_6:part_2")}
            </span>
          </Text>
        </Flex>

        <Flex
          flexDir="column"
          my={6}
          w="full"
          justifyContent="center"
          alignItems="center"
        >
          <Checkbox
            isChecked={haveAgreed}
            color="brand"
            onChange={(e) => setHaveAgreed(e.target.checked)}
          >
            {t("affiliate:checkbox:part_1")}{" "}
            <span
              onClick={() => navigate("/affiliate-terms")}
              style={{
                color: "#50C8FC",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {t("affiliate:checkbox:part_2")}{" "}
            </span>{" "}
          </Checkbox>
        </Flex>

        {haveAgreed && (
          <Flex
            flexDir="column"
            mb={6}
            w="full"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              // bg="white"
              variant="outline"
              // color="island"
              fontSize={16}
              fontWeight={500}
              mt={4}
              maxW={200}
              onClick={async () => {
                setIsCreating(true);

                let errMsg = "RECORD_NOT_UNIQUE";
                let counter = 0;

                while (errMsg === "RECORD_NOT_UNIQUE" && counter < 20) {
                  const affilCode = makeid(6);

                  const affilCodeItem: ItemInput<UserItem> = {
                    value: affilCode,
                    owner: user?.id,
                  };

                  counter += 1;
                  try {
                    await directus.items("aa_affil").createOne(affilCodeItem);
                    errMsg = "NULL";
                    toast.success("AffilCode byl vygenerován");
                  } catch (error) {
                    errMsg = (error as any)?.response?.errors?.[0]?.extensions
                      ?.code;
                  }
                }

                if (counter >= 20) {
                  toast.error(
                    "Nepodařilo se vygenerovat affilCode, prosím kontaktujte nás"
                  );
                }

                setIsCreating(false);
                setHaveAgreed(false);
                fetchMyBilling();
              }}
              _hover={{
                borderColor: "brand",
                color: "brand",
              }}
            >
              {isCreating ? <Spinner /> : t("affiliate:button")}
            </Button>
          </Flex>
        )}
        <Flex flexDir="column" w="full" mt={6}>
          <TableContainer>
            <Table variant="simple">
              {/* <TableCaption>
                {tableData.map((item) => (
                  <Text>https://hodlbot.cz/#ref={item.code}</Text>
                ))}
              </TableCaption> */}
              <Thead color="white">
                <Tr>
                  <Th color="brand">{t("affiliate:table:text_1")}</Th>
                  <Th color="brand">{t("affiliate:table:text_2")}</Th>
                  <Th color="brand">{t("affiliate:table:text_3")}</Th>
                  <Th color="brand" isNumeric>
                    {t("affiliate:table:text_4")}
                  </Th>
                  <Th color="brand" isNumeric>
                    {t("affiliate:table:text_5")}
                  </Th>
                  <Th color="brand">{t("affiliate:table:text_6")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData.map((item) => (
                  <Tr>
                    <Td>{item.code}</Td>
                    <Td>{item.affilUsers.Registered}</Td>
                    <Td>{item.affilUsers.Active}</Td>
                    <Td color="green.400" isNumeric>
                      {item.affilUsers.Paid}
                    </Td>
                    <Td color="orange.400" isNumeric>
                      {item.affilUsers.Reward}
                    </Td>
                    <Td>
                      {" "}
                      <IconButton
                        size="sm"
                        mr={4}
                        colorScheme="brand"
                        aria-label="Copy to clipboard"
                        icon={<CopyIcon />}
                        border="1px solid white"
                        _hover={{
                          border: "1px solid #50C8FC",
                          color: "brand",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://hodlbot.cz/#ref=${item.code}`
                          );
                          toast.success(t("affiliate:message:copy"));
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th />
                  <Th />
                  <Td color="brand">{t("affiliate:table:text_7")}</Td>
                  <Td color="green.400" isNumeric>
                    {tableData.reduce(
                      (total, item) => total + parseFloat(item.affilUsers.Paid),
                      0
                    )}
                  </Td>
                  <Td color="orange.400" isNumeric>
                    {tableData.reduce(
                      (total, item) =>
                        total + parseFloat(item.affilUsers.Reward),
                      0
                    )}
                  </Td>
                  <Td />
                </Tr>
              </Tfoot>
            </Table>
          </TableContainer>
        </Flex>
      </Island>
    </>
  );
};

export default Affil;
