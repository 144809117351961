import {
  Flex,
  Select,
  Text,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { getDataGraphs, getDataTables } from "../api/graphs";
import Graph from "../components/graph/Graph";
import ParentSize from "@visx/responsive/lib/components/ParentSize";

import Island from "../components/Island";
import { Flag, GraphData, GraphRecord, TableRecord } from "../utils/types";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import img_1 from "../images/1.png";
import img_2 from "../images/2.png";

import { ReactComponent as CheckIcon } from "../icons/check_circle.svg";

import { useTranslation } from "react-i18next";
import { directus } from "../api/directus";
import { toast } from "react-toastify";

const dark = (number: number, higherThan: number) =>
  number !== 0 ? "#A6F787" : "#FF75CB";

const Statistics = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const { t } = useTranslation();

  const [type, setType] = useState<"GRAPHS" | "TABLE">("TABLE");

  const [selectedSymbol, setSelectedSymbol] = useState<Flag>(Flag.ACTIVE);
  const [graphsData, setGraphsData] = useState<any>({});
  const [tableData, setTableData] = useState<TableRecord[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  console.log(selectedSymbol);

  // Initial data fetch.
  useEffect(() => {
    const getGraphs = async () => {
      setIsLoading(true);
      const token = await directus.auth.token;

      await fetch(
        `https://api.botcalculator.com/hb/v1/stats/public/stats/graph`,
        {
          headers: {
            AuthToken: token || "",
          },
        }
      ).then(async (res) => {
        if (res.ok) {
          const json = await res.json();
          console.log("json", json);
          setGraphsData(json);
          setIsLoading(false);
        } else {
          toast("HTTP-Error: " + res.status, { type: "error" });
        }
      });
    };

    const getTables = async () => {
      const token = await directus.auth.token;

      await fetch(
        `https://api.botcalculator.com/hb/v1/stats/public/stats/current_data`,
        {
          headers: {
            AuthToken: token || "",
          },
        }
      ).then(async (res) => {
        if (res.ok) {
          const json = await res.json();
          setTableData(json);
        } else {
          toast("HTTP-Error: " + res.status, { type: "error" });
        }
      });
    };

    getGraphs();
    getTables();
  }, []);

  const instanceIds = useMemo(
    () =>
      tableData
        .map((item) => item.Symbol)
        .filter(
          (item, index) =>
            tableData.map((item) => item.Symbol).indexOf(item) === index
        ),
    [tableData]
  );

  const filteredTableData = useMemo(
    () =>
      tableData.filter((d) =>
        d.Flag.map((flag) => flag.toLocaleLowerCase()).includes(
          selectedSymbol.toLocaleLowerCase()
        )
      ),
    [selectedSymbol, tableData]
  );

  if (isLoading) {
    return (
      <>
        <Island>
          <Text align="center" fontSize="3xl" width="full" mb={10}>
            {t("portfolio:instance_detail:title")}
          </Text>
          <Flex justifyContent="center" alignItems="center" h="full">
            <Spinner />
          </Flex>
        </Island>
      </>
    );
  }

  return (
    <>
      <Island>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          w="full"
        >
          <Flex
            border="1px solid #50C8FC"
            mx={4}
            borderRadius="lg"
            cursor="pointer"
          >
            <Flex
              m={0}
              p={2}
              onClick={() => setType("TABLE")}
              bg={type === "TABLE" ? "#50C8FC" : "none"}
              color={type === "TABLE" ? "#14142A" : "white"}
              borderRadius="md"
              alignItems="center"
            >
              {type === "TABLE" && <CheckIcon fill="#14142A" />}
              <Text ml={2} fontWeight={300}>
                {t("statisticst:statistics")}
              </Text>
            </Flex>
            <Flex
              m={0}
              p={2}
              onClick={() => setType("GRAPHS")}
              bg={type === "GRAPHS" ? "#50C8FC" : "none"}
              color={type === "GRAPHS" ? "#14142A" : "white"}
              borderRadius="md"
              alignItems="center"
            >
              {type === "GRAPHS" && <CheckIcon fill="#14142A" />}
              <Text ml={2}> {t("statistics:graphs")}</Text>
            </Flex>
          </Flex>

          <Select
            w="auto"
            variant="outline"
            onChange={(event) => setSelectedSymbol(event.target.value as Flag)}
            borderColor="#6E7191"
          >
            <option
              key="ACTIVE"
              value={Flag.ACTIVE}
              style={{ background: "#14142A" }}
            >
              {t("statistics:active")}
            </option>

            <option
              key="SHOWALL"
              value={Flag.SHOW_ALL}
              style={{ background: "#14142A" }}
            >
              {t("statistics:all")}
            </option>

            {instanceIds
              .filter((id) => id !== "backtest")
              .map((id) => (
                <option key={id} value={id} style={{ background: "#14142A" }}>
                  {id}
                </option>
              ))}

            <option
              key="WAITINGMODE"
              value={Flag.WAITING}
              style={{ background: "#14142A" }}
            >
              {t("statistics:pending")}
            </option>
            <option
              key="ENDED"
              value={Flag.ENDED}
              style={{ background: "#14142A" }}
            >
              {t("statistics:ended")}
            </option>
          </Select>
        </Flex>
      </Island>
      <Island>
        {type === "TABLE" ? (
          <>
            <Tooltip anchorId="profit_1" style={{ opacity: 1 }}>
              <div>
                <img alt="1" src={img_1} />
              </div>
            </Tooltip>
            <Tooltip anchorId="profit_2" style={{ opacity: 1 }}>
              <div>
                <img alt="1" src={img_2} />
              </div>
            </Tooltip>

            <Flex
              borderRadius="lg"
              overflow="hidden"
              overflowX="auto"
              w="full"
              css={{
                "::-webkit-scrollbar": {
                  width: "12px",
                },
                "::-webkit-scrollbar-track": {
                  "background-color": "#262338",
                  border: "1px solid #14142A",
                  "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
                },

                "::-webkit-scrollbar-thumb": {
                  "background-color": "rgba(0, 0, 0, .3)",
                },
              }}
            >
              <Table>
                <Thead position="sticky" top={0} bg="#4E4B66">
                  <Tr>
                    <Th align="right" color="white">
                      Instance ID
                    </Th>
                    <Th align="right" color="white">
                      Buy
                    </Th>
                    <Th align="right" color="white">
                      Sell
                    </Th>
                    <Th align="right" color="white">
                      Transactions per month
                    </Th>
                    <Th align="right" color="white">
                      Profit crypto
                    </Th>

                    <Th id="profit_1" align="right" color="white">
                      Profit of portfolio [%]
                    </Th>

                    <Th id="profit_2" align="left" color="white">
                      Profit of portfolio2 [%] *
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredTableData
                    .sort((a, b) => a.InstanceId.localeCompare(b.InstanceId))
                    .map((row) => (
                      <Tr key={row.InstanceId} height={10}>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.InstanceId}
                        </Th>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.Buy}
                        </Th>
                        <Th
                          align="right"
                          color="#EFF0F6"
                          fontWeight="normal"
                          border="1px solid #262338"
                        >
                          {row.Sell}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.TransactionAverage, 200)}
                          border="1px solid #262338"
                        >
                          {row.TransactionAverage}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.ProfitCrypto, 0)}
                          border="1px solid #262338"
                        >
                          {row.ProfitCrypto}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.PP1, 0)}
                          border="1px solid #262338"
                        >
                          {row.PP1}
                        </Th>
                        <Th
                          align="right"
                          color={dark(row.PP2, 0)}
                          border="1px solid #262338"
                        >
                          {row.PP2}
                        </Th>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </Flex>
          </>
        ) : (
          <Flex
            alignItems="center"
            flexDirection="column"
            width="full"
            overflowX="hidden"
            paddingBottom={100}
          >
            {Object.keys(graphsData as any)
              .sort((a, b) => a.localeCompare(b))
              .map(
                (key) =>
                  graphsData[key]?.Snapshots?.[0]?.Flag.map((flag: string) =>
                    flag.toLocaleLowerCase()
                  ).includes(selectedSymbol.toLocaleLowerCase()) && (
                    <Flex
                      key={key}
                      my={10}
                      flexDir="column"
                      w="full"
                      alignItems="center"
                    >
                      <Text align="left" width="full" fontSize="2xl">
                        {key}
                      </Text>
                      <ParentSize>
                        {({ width }) => (
                          <Flex justifyContent="center">
                            <Graph
                              width={width}
                              height={500}
                              fullData={graphsData[key].Snapshots}
                            />
                          </Flex>
                        )}
                      </ParentSize>
                    </Flex>
                  )
              )}
          </Flex>
        )}
      </Island>
    </>
  );
};

export default Statistics;
