import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  HStack,
  VStack,
  Image,
  Checkbox,
  Divider,
  Icon,
  Select,
  Link,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { directus } from "../api/directus";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Binance } from "./MultiStepForm/Binance";
import { Phemex } from "./MultiStepForm/Phemex";
import { Bybit } from "./MultiStepForm/Bybit";
import { CoinMate } from "./MultiStepForm/Coinmate";

type Form1Props = {
  burza: "BINANCE" | "PHEMEX" | "COINMATE" | "BYBIT" | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  onClose: () => void;
};

const Form1 = ({ burza, setStep, onClose }: Form1Props) => {
  const { t } = useTranslation();

  return (
    <>
      <HStack>
        <Icon
          color="white"
          as={AiOutlineArrowLeft}
          fontSize="2xl"
          my={3}
          style={{
            cursor: "pointer",
          }}
          onClick={() => onClose()}
        />
        <Text
          w="100%"
          fontSize="2xl"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
          color="white"
        >
          {t("api_keys:title_1")}
        </Text>
      </HStack>

      <Flex flexDir="column" maxW={1000}>
        {burza === "BINANCE" ? (
          <Binance setStep={setStep} />
        ) : burza === "PHEMEX" ? (
          <Phemex setStep={setStep} />
        ) : burza === "BYBIT" ? (
          <Bybit setStep={setStep} />
        ) : burza === "COINMATE" ? (
          <CoinMate setStep={setStep} />
        ) : (
          <Text>Not implemented</Text>
        )}
      </Flex>
    </>
  );
};

type Form2Props = {
  burza: "BINANCE" | "PHEMEX" | "COINMATE" | "BYBIT" | undefined;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  refetch: () => Promise<void>;
  onClose: () => void;
};

const Form2 = ({ burza, setStep, refetch, onClose }: Form2Props) => {
  const { t } = useTranslation();

  const [showSave, setShowSave] = useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [clientId, setClientId] = useState<null | string>(null);
  const [apiKey, setApiKey] = useState("");

  const onSubmit = async () => {
    try {
      const token = await directus.auth.token;
      const res = await fetch(`https://api.botcalculator.com/v1/user/api`, {
        method: "POST",
        headers: {
          AuthToken: token || "",
        },
        body: JSON.stringify({
          type: burza,
          key: apiKey,
          client_id: clientId,
          secret: secretKey,
        }),
      });
      const json = await res.json();
      console.log(json);
      toast(t("api_keys:notif_success"), { type: "success" });
      await refetch();
      onClose();
    } catch (error) {
      toast(t("api_keys:notif_error"), { type: "error" });
    }
  };

  return (
    <>
      <Text
        w="100%"
        fontSize="2xl"
        textAlign={"center"}
        fontWeight="normal"
        mb="2%"
        color="white"
      >
        {t("api_keys:title_2:part_1")}{" "}
        {burza === "BINANCE"
          ? t("api_keys:title_2:binance")
          : burza === "BYBIT"
          ? t("api_keys:title_2:bybit")
          : burza === "COINMATE"
          ? t("api_keys:title_2:coinmate")
          : burza === "PHEMEX"
          ? t("api_keys:title_2:phemex")
          : ""}
      </Text>

      <FormControl my={2}>
        <FormLabel htmlFor="apiKey"> {t("api_keys:text_1")}</FormLabel>
        <Input
          placeholder="*********"
          bg="island"
          onChange={(val) => setApiKey(val?.target.value || "")}
          value={apiKey}
          color="white"
        />
      </FormControl>

      <FormControl>
        <FormLabel color="#D9DBE9" htmlFor="secretKey">
          {t("api_keys:text_2")}
        </FormLabel>
        <Input
          placeholder="*********"
          bg="island"
          onChange={(val) => setSecretKey(val?.target.value || "")}
          value={secretKey}
          color="white"
        />
      </FormControl>

      {burza === "COINMATE" && (
        <FormControl my={2}>
          <FormLabel color="#D9DBE9" htmlFor="clientId">
            {t("api_keys:text_18")}
          </FormLabel>
          <Input
            placeholder="*********"
            bg="island"
            onChange={(val) => setClientId(val?.target.value || "")}
            value={clientId || ""}
            color="white"
          />
        </FormControl>
      )}

      <HStack spacing={5} mt={5}>
        <Checkbox
          isChecked={showSave}
          onChange={() => setShowSave(!showSave)}
        />
        <Text color="white">{t("api_keys:text_6")}</Text>
      </HStack>

      <HStack justifyContent="space-between" mt={5}>
        <Button
          bg="brand"
          color="island"
          fontSize={16}
          fontWeight={400}
          onClick={() => {
            setStep(1);
          }}
        >
          {t("api_keys:button_3")}
        </Button>

        {showSave && (
          <Button
            bg="brand"
            color="island"
            fontSize={14}
            fontWeight={500}
            onClick={onSubmit}
          >
            {t("api_keys:button_2")}
          </Button>
        )}
      </HStack>
    </>
  );
};

type Props = {
  refetch: () => Promise<void>;
  onClose: () => void;
  burza: "BINANCE" | "PHEMEX" | "COINMATE" | "BYBIT" | undefined;
};

export default function Multistep({ refetch, onClose, burza }: Props) {
  const [step, setStep] = useState(1);

  return (
    <>
      <Box rounded="lg" p={6} m="10px auto" as="form">
        {step === 1 ? (
          <Form1 burza={burza} setStep={setStep} onClose={onClose} />
        ) : (
          <Form2
            burza={burza}
            setStep={setStep}
            refetch={refetch}
            onClose={onClose}
          />
        )}
      </Box>
    </>
  );
}
