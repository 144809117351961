import {
  Button,
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { ChangeEventHandler, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Input from "./Input";

import { ReactComponent as LockIcon } from "../icons/lock.svg";
import { ReactComponent as VisibilityIcon } from "../icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../icons/visibility_off.svg";

type Props = {
  id: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  value?: string | number | readonly string[] | undefined;
  placeholder?: string;
};

const PasswordInput = ({ id, onChange, value, placeholder }: Props) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <Input
      id={id}
      onChange={onChange}
      value={value}
      type={show ? "text" : "password"}
      placeholder={placeholder}
      iconLeft={<LockIcon fill="#FFFFFF" />}
      iconRight={
        // <Button h="1.75rem" mr={2} onClick={handleClick} color="bg" bg="bg">
        //   {show ? (
        //     <VisibilityIcon fill="#FFFFFF" />
        //   ) : (
        <Flex onClick={handleClick} cursor="pointer">
          {show ? (
            <VisibilityIcon fill="#FFFFFF" />
          ) : (
            <VisibilityOffIcon fill="#FFFFFF" />
          )}
        </Flex>
        // )}
        // </Button>
      }
    />
  );
};

export default PasswordInput;
