import {
  Flex,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  Select,
  Button,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import Island from "../components/Island";
import NumberInput from "../components/NumberInput";
import Row from "../components/Row";
import { useTranslation } from "react-i18next";

import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export type data = {
  Capital?: number;
  InitFiat?: number;
  InitPrice?: number;
  PriceUnit?: number;
  Profit?: number;
  Ratio?: number;
} | null;

type Props = {
  pair?: string;
  buyPercentage?: string;
  roundTo?: string;
  priceUnit?: string;
  capital?: string;
  fee?: string;
  currentPrice?: string;
  currentFiat?: number;
  mode?: string;
  displayBanner?: boolean;
};

const Calculator = (props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);
  const { t } = useTranslation();
  const minIndex = -5;
  const absMinIndex = Math.abs(minIndex);
  const maxIndex = 25;

  const [pair, setPair] = useState<string>(props.pair ?? "BTC/USDT");
  const [buyPercentage, setBuyPercentage] = useState<string>(
    props.buyPercentage ?? "2"
  );
  const [roundTo, setRoundTo] = useState<string>(props.roundTo ?? "5");
  const [priceUnit, setPriceUnit] = useState<string>(props.priceUnit ?? "20");
  const [capital, setCapital] = useState<string>(props.capital ?? "650");
  const [fee, setFee] = useState<string>(props.fee ?? "0.065");

  const [currentPrice, setCurrentPrice] = useState<string>(
    props.currentPrice ?? "45000"
  );
  const [currentFiat, setCurrentFiat] = useState<number>(
    props?.currentFiat ?? 200
  );

  let magicConstant = 1.5;

  const initBalanceCrypto =
    (parseFloat(capital) - currentFiat) / parseFloat(currentPrice);

  const getCurrentPrice = (index: number, useNegativeFormula = false) => {
    return round(
      index < 0 || useNegativeFormula
        ? parseFloat(currentPrice) /
            Math.pow(1 + parseFloat(buyPercentage) * 0.01, Math.abs(index))
        : parseFloat(currentPrice) *
            Math.pow(1 + parseFloat(buyPercentage) * 0.01, Math.abs(index))
    );
  };

  // crypto balance for negative index
  const negativeCryptoBalance = Array(absMinIndex)
    .fill({})
    .reduce((previous, curr, index) => {
      const previousCryptoBalance =
        index === 0 ? initBalanceCrypto : previous[previous.length - 1];
      const newOne = round(
        previousCryptoBalance +
          roundUp(
            parseFloat(priceUnit) / getCurrentPrice(index + 1, true),
            parseFloat(roundTo)
          )
      );
      return [...previous, newOne];
    }, []);

  const values: any[] = Array(absMinIndex + maxIndex + 1)
    .fill({})
    .reduce((previousValue) => {
      const index = previousValue.length
        ? previousValue[previousValue.length - 1].index + 1
        : minIndex;
      const price = getCurrentPrice(index);
      const newItem = {
        index,
        procenta: round((price / parseFloat(currentPrice) - 1) * 100, 2),
        koupitKrypto: roundUp(
          parseFloat(priceUnit) / price,
          parseFloat(roundTo)
        ),
        cena: price,
        prodatKrypto: roundUp(
          parseFloat(priceUnit) / price / (1 - parseFloat(fee) * 0.01),
          parseFloat(roundTo)
        ),
        fiat: round(currentFiat + parseFloat(priceUnit) * index, 2),
        zustatekKrypto: null,
        ziskKrypto: null,
        ziskFiat: null,
      };
      newItem.zustatekKrypto = round(
        index === 0
          ? initBalanceCrypto
          : index > 0
          ? previousValue[previousValue.length - 1].zustatekKrypto -
            newItem.prodatKrypto
          : negativeCryptoBalance[Math.abs(index) - 1]
      ) as any;

      if (!previousValue.length) {
        newItem.ziskKrypto = "-" as any;
        newItem.ziskFiat = "-" as any;
        return [newItem];
      }
      newItem.ziskKrypto = round(
        previousValue[previousValue.length - 1].koupitKrypto -
          newItem.prodatKrypto
      ) as any;
      newItem.ziskFiat = round((newItem.ziskKrypto ?? 1) * price, 2) as any;
      return [...previousValue, newItem];
    }, []);

  const minBuyOrderCount = roundDown(currentFiat / parseFloat(priceUnit), 0);
  const minBuyPrice = round(
    parseFloat(currentPrice) /
      Math.pow(1 + parseFloat(buyPercentage) * 0.01, minBuyOrderCount)
  );
  const minBuyDiff = round(
    (minBuyPrice / parseFloat(currentPrice) - 1) * 100,
    2
  );

  const maxSellOrderCount = roundDown(
    Math.log(
      (initBalanceCrypto * (1 - parseFloat(buyPercentage) * 0.01 - 1)) /
        (parseFloat(priceUnit) / parseFloat(currentPrice)) +
        1
    ) / Math.log(1 - parseFloat(buyPercentage) * 0.01),
    0
  );

  const maxSellPrice = round(
    Math.pow(1 + parseFloat(buyPercentage) * 0.01, maxSellOrderCount) *
      parseFloat(currentPrice)
  );
  const maxSellDiff = round(
    (maxSellPrice / parseFloat(currentPrice) - 1) * 100,
    2
  );

  const months = parseFloat(capital) / (parseFloat(priceUnit) * magicConstant);
  const years = months / 12;
  const annualInterest = (Math.pow(2, 1 / years) - 1) * 100;
  const profit = 12 * magicConstant * parseFloat(priceUnit);

  function roundDown(int: number, decimal = 6): number {
    return Math.floor(int * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }
  function roundUp(int: number, decimal = 6): number {
    return Math.ceil(int * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }
  function round(int: number, decimal = 6): number {
    return Math.round(int * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  return (
    <>
      {props.displayBanner && (
        <Island id="#introduction" backgroundImage="about_first.png">
          <Text fontSize="3xl" fontWeight="bold" mb={8}>
            {t("introduction:section_1:title")}
          </Text>

          <Flex gap={4}>
            <Button
              bg="brand"
              color="island"
              fontSize={16}
              fontWeight={400}
              onClick={() => navigate("/signup")}
            >
              {t("introduction:section_1:button")}
              <Icon ml={2} as={BsArrowRight} />
            </Button>

            <a
              href="https://calendly.com/hodlbot/30min"
              target="_blank"
              rel="noreferrer"
            >
              <Button bg="white" color="island" fontSize={16} fontWeight={400}>
                {t("online_meeting:title")}
                <Icon ml={2} as={BsArrowRight} />
              </Button>
            </a>
          </Flex>
        </Island>
      )}

      <Island id="#basic_information">
        <Flex
          flexDir={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          w="full"
          gap={10}
        >
          <Flex flexDir={{ base: "column", md: "row" }} gap={10}>
            <Flex flexDir="column" justifyContent="space-between">
              <Flex flexDir="column">
                <Flex py={1}>
                  <Text
                    display="flex"
                    fontSize={12}
                    h="full"
                    alignItems="center"
                    mr={2}
                  >
                    {t("calculator:traded_pair")}
                  </Text>
                  <Tooltip label={t("calculator:information_1")}>
                    <InfoIcon fill="#50C8FC" width={20} />
                  </Tooltip>
                </Flex>
                <Input
                  disabled={props.mode === "display"}
                  bg="#9E0038"
                  borderColor="#FF54AF"
                  value={pair}
                  onChange={(e) => setPair(e.target.value)}
                  _disabled={{
                    opacity: 1,
                    cursor: "not-allowed",
                  }}
                />
              </Flex>
              <Flex flexDir="column">
                <Flex py={1}>
                  <Text
                    display="flex"
                    fontSize={12}
                    h="full"
                    alignItems="center"
                    mr={2}
                  >
                    {t("calculator:jump")}
                  </Text>
                  <Tooltip label={t("calculator:information_2")}>
                    <InfoIcon fill="#50C8FC" width={20} />
                  </Tooltip>
                </Flex>
                <NumberInput
                  disabled={props.mode === "display"}
                  bg="#946300"
                  step={0.5}
                  borderColor="#FDCC62"
                  value={buyPercentage}
                  showPercentage
                  onChange={(e) => setBuyPercentage(e)}
                  min={0}
                />
              </Flex>
              <Flex flexDir="column">
                <Flex py={1}>
                  <Text
                    display="flex"
                    fontSize={12}
                    h="full"
                    alignItems="center"
                    mr={2}
                  >
                    {t("calculator:exchange")}
                  </Text>
                  <Tooltip label={t("calculator:information_3")}>
                    <InfoIcon fill="#50C8FC" width={20} />
                  </Tooltip>
                </Flex>
                <Select
                  disabled={props.mode === "display"}
                  borderColor="#50C8FC"
                  defaultValue={roundTo}
                  onChange={(e) => setRoundTo(e.target.value)}
                  colorScheme="blue"
                  style={{
                    width: "100%",
                    color: "white",
                    background: "#14142A",
                    border: "1px solid #6E7191",
                    borderRadius: "5px",
                    height: "40px",
                    padding: "0 16px",
                  }}
                  _disabled={{
                    opacity: 1,
                    cursor: "not-allowed",
                  }}
                >
                  <option
                    value="5"
                    style={{
                      background: "#14142A",
                      border: "1px solid #6E7191",
                    }}
                  >
                    Binance
                  </option>
                  <option
                    value="6"
                    style={{
                      background: "#14142A",
                      border: "1px solid #6E7191",
                    }}
                  >
                    Phemex
                  </option>
                </Select>
              </Flex>
            </Flex>
            <Flex flexDir="column" justifyContent="space-between">
              <Flex flexDir="column">
                <Flex py={1}>
                  <Text
                    display="flex"
                    fontSize={12}
                    h="full"
                    alignItems="center"
                    mr={2}
                  >
                    {t("calculator:trade_volume")}
                  </Text>
                  <Tooltip label={t("calculator:information_4")}>
                    <InfoIcon fill="#50C8FC" width={20} />
                  </Tooltip>
                </Flex>
                <NumberInput
                  disabled={props.mode === "display"}
                  bg="#946300"
                  borderColor="#FDCC62"
                  value={priceUnit}
                  onChange={(e) => setPriceUnit(e)}
                  min={0}
                />
              </Flex>
              <Flex flexDir="column">
                <Flex py={1}>
                  <Text
                    display="flex"
                    fontSize={12}
                    h="full"
                    alignItems="center"
                    mr={2}
                  >
                    {t("calculator:fee_in")}
                  </Text>
                  <Tooltip label={t("calculator:information_5")}>
                    <InfoIcon fill="#50C8FC" width={20} />
                  </Tooltip>
                </Flex>
                <NumberInput
                  disabled={props.mode === "display"}
                  bg="#EE6D00"
                  borderColor="#FF9943"
                  value={fee}
                  onChange={(e) => setFee(e)}
                  step={0.1}
                  showPercentage
                  min={0}
                />
              </Flex>
              <Flex flexDir="column">
                <Flex py={1}>
                  <Text
                    display="flex"
                    fontSize={12}
                    h="full"
                    alignItems="center"
                    mr={2}
                  >
                    {t("calculator:capital")}
                  </Text>
                  <Tooltip label={t("calculator:information_6")}>
                    <InfoIcon fill="#50C8FC" width={20} />
                  </Tooltip>
                </Flex>
                <NumberInput
                  disabled={props.mode === "display"}
                  bg="#EAAC30"
                  borderColor="#FDCC62"
                  value={capital}
                  onChange={(e) => setCapital(e)}
                  min={0}
                />
              </Flex>
            </Flex>
          </Flex>

          {props.mode !== "display" && (
            <Flex justifyContent="center" alignItems="center" flexDir="column">
              <Text
                align="center"
                onClick={() =>
                  window.open(
                    "http://hodlbot.cz/articles/18685980-731d-4c69-a06e-ff0695623281"
                  )
                }
              >
                <b style={{ color: "#50C8FC" }}>FAQ</b>{" "}
                {t("calculator:guide_to_hodlbot_part_6")}{" "}
                <b
                  style={{
                    color: "#50C8FC",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {t("calculator:guide_to_hodlbot_part_5")}
                </b>
              </Text>
              <Text
                align="center"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=-Yk7t2rrkfo&ab_channel=Tom%C3%A1%C5%A1Velek"
                  )
                }
              >
                {t("calculator:guide_to_hodlbot_part_1")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("calculator:guide_to_hodlbot_part_2")}
                </b>{" "}
                {t("calculator:guide_to_hodlbot_part_3")}{" "}
                <b style={{ color: "#50C8FC" }}>
                  {t("calculator:guide_to_hodlbot_part_4")}{" "}
                </b>
                <b
                  style={{
                    color: "#50C8FC",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  {t("calculator:guide_to_hodlbot_part_5")}
                </b>
              </Text>
            </Flex>
          )}

          <Flex flexDir={{ base: "column", md: "row" }} gap={10}>
            <Flex
              borderRadius="lg"
              overflow="hidden"
              overflowX="auto"
              w="full"
              css={{
                "::-webkit-scrollbar": {
                  width: "12px",
                },
                "::-webkit-scrollbar-track": {
                  "background-color": "#262338",
                  border: "1px solid #14142A",
                  "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
                },

                "::-webkit-scrollbar-thumb": {
                  "background-color": "rgba(0, 0, 0, .3)",
                },
              }}
            >
              <Table fontSize={13}>
                <Thead bg="#4E4B66">
                  <Tr>
                    <Th border="1px solid #4E4B66" />
                    <Th
                      border="1px solid #4E4B66"
                      p={2}
                      fontSize={11}
                      color="white"
                    >
                      <Text align="center"> PRICE</Text>
                    </Th>
                    <Th
                      border="1px solid #4E4B66"
                      p={2}
                      fontSize={11}
                      color="white"
                    >
                      <Text align="center"> ORDER COUNT</Text>
                    </Th>
                    <Th
                      border="1px solid #4E4B66"
                      p={2}
                      fontSize={11}
                      color="white"
                    >
                      <Text align="center"> DIFF</Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr border="1px solid #4E4B66">
                    <Td
                      p={2}
                      bg="#4E4B66"
                      fontSize={12}
                      border="1px solid #4E4B66"
                    >
                      <Text align="center">
                        MIN
                        <br />
                        BUY
                      </Text>
                    </Td>
                    <Td p={2} border="1px solid #4E4B66">
                      <Text align="center"> {minBuyPrice.toFixed(3)}</Text>
                    </Td>
                    <Td p={2} border="1px solid #4E4B66">
                      <Text align="center"> {minBuyOrderCount}</Text>
                    </Td>
                    <Td p={2} border="1px solid #4E4B66">
                      <Text align="center">{minBuyDiff} %</Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      p={2}
                      bg="#4E4B66"
                      border="1px solid #4E4B66"
                      fontSize={12}
                    >
                      <Text align="center">
                        MAX
                        <br />
                        SELL
                      </Text>
                    </Td>
                    {maxSellOrderCount ? (
                      <>
                        <Td p={2} border="1px solid #4E4B66">
                          <Text align="center"> {maxSellPrice.toFixed(3)}</Text>
                        </Td>
                        <Td p={2} border="1px solid #4E4B66">
                          <Text align="center"> {maxSellOrderCount}</Text>
                        </Td>
                        <Td p={2} border="1px solid #4E4B66">
                          <Text align="center">{maxSellDiff} %</Text>
                        </Td>
                      </>
                    ) : (
                      <Td p={2} colSpan={3} border="1px solid #4E4B66">
                        <Text align="center">
                          Prodej je možný do nekonečna a to je chtěné.
                        </Text>
                      </Td>
                    )}
                  </Tr>
                </Tbody>
              </Table>
            </Flex>
          </Flex>
        </Flex>
      </Island>
      <Island>
        <Flex
          borderRadius="lg"
          overflow="hidden"
          overflowX="scroll"
          w="full"
          scrollBehavior="smooth"
          css={{
            "::-webkit-scrollbar": {
              width: "12px",
            },
            "::-webkit-scrollbar-track": {
              "background-color": "#262338",
              border: "1px solid #14142A",
              "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
            },

            "::-webkit-scrollbar-thumb": {
              "background-color": "rgba(0, 0, 0, .3)",
            },
          }}
        >
          <Table fontSize={13}>
            <Thead bg="#4E4B66">
              <Tr>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Text align="center"> {t("calculator:index")}</Text>
                </Th>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Text align="center"> {t("calculator:percentages")}</Text>
                </Th>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <Text align="center" mr={2}>
                      {t("calculator:price")}
                    </Text>
                    <Tooltip label={t("calculator:information_7")}>
                      <InfoIcon fill="#50C8FC" width={20} />
                    </Tooltip>
                  </Flex>
                </Th>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Flex alignItems="center" justifyContent="center">
                    <Text mr={2}>{t("calculator:fiat")}</Text>
                    <Tooltip label={t("calculator:information_8")}>
                      <InfoIcon fill="#50C8FC" width={20} />
                    </Tooltip>
                  </Flex>
                </Th>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Text align="center"> {t("calculator:crypto_balance")}</Text>
                </Th>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Text align="center"> {t("calculator:crypto_profit")}</Text>
                </Th>
                <Th
                  border="1px solid #4E4B66"
                  p={2}
                  fontSize={11}
                  color="white"
                >
                  <Text align="center"> {t("calculator:fiat_profit")}</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {(props.mode === "display" ? values.slice(5, 6) : values).map(
                (item) => (
                  <Row
                    disabled={props.mode === "display"}
                    item={item}
                    setCurrentFiat={setCurrentFiat}
                    setCurrentPrice={setCurrentPrice}
                    currentPrice={currentPrice}
                    currentFiat={currentFiat}
                  />
                )
              )}
            </Tbody>
          </Table>
        </Flex>
      </Island>
    </>
  );
};

export default Calculator;
