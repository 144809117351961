import { useMutation } from '@tanstack/react-query';
import { directus } from '../directus';

type Props = {
  body: {
    api_id: string;
    name: string;
    price_unit: number;
    ticker: string;
    frequency: number;
    time_unit: string;
    next_action_time: string;
  };
};

export const useAddDcaInstance = () =>
  useMutation({
    mutationFn: async ({ body }: Props) => {
      const token = await directus.auth.token;

      const res = await fetch(
        `https://api.botcalculator.com/v1/dca/dca_instance/create`,
        {
          method: 'POST',
          headers: {
            AuthToken: token || '',
          },
          body: JSON.stringify(body),
        }
      );
      const json = await res.json();

      return json;
    },
  });
