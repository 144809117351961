import React, { useMemo, useCallback } from "react";
import { Line, Bar, LinePath } from "@visx/shape";
import { GridRows, GridColumns } from "@visx/grid";
import { scaleTime, scaleLinear } from "@visx/scale";
import {
  withTooltip,
  Tooltip,
  TooltipWithBounds,
  defaultStyles,
} from "@visx/tooltip";
import { WithTooltipProvidedProps } from "@visx/tooltip/lib/enhancers/withTooltip";
import { localPoint } from "@visx/event";
import { max, extent, bisector } from "d3-array";
import { timeFormat } from "d3-time-format";
import { GraphRecord } from "../../utils/types";
import { AxisBottom, AxisRight } from "@visx/axis";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { Group } from "@visx/group";
import { Text } from "@chakra-ui/react";

type TooltipData = GraphRecord;

const profitCryptoColor = "#6DFDED";
const lastActionPriceColor = "#50C8FC";
const closedTransactionsColor = "#A6F787";
const currentPortfolioValueColor = "#FF75CB";
const currentFiatColor = "#FFA65A";
const currentCryptoColor = "#B78CFF";

const background = "#3b6978";
const accentColor = "#edffea";
const accentColorDark = "#75daad";
const tooltipStyles = {
  ...defaultStyles,
  background,
  border: "1px solid white",
  color: "white",
};

// util
const formatDate = timeFormat("%b %d, '%y");

// accessors
const getDate = (d: GraphRecord) => new Date(d.Date);
const getProfitCrypto = (d: GraphRecord) => d.ProfitCrypto;
const getLastActionPrice = (d: GraphRecord) => d.LastActionPrice;
const getClosedTransactions = (d: GraphRecord) => d.ClosedTransactions;
const getCurrentPortfolioValue = (d: GraphRecord) => d.CurrentPortfolioValue;
const getCurrentFiat = (d: GraphRecord) => d.CurrentFiat;
const getCurrentCrypto = (d: GraphRecord) => d.CurrentCrypto;

const bisectDate = bisector<GraphRecord, Date>((d) => new Date(d.Date)).left;

export type AreaProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  fullData: GraphRecord[];
};

const areaPadding = 20;

export default withTooltip<AreaProps, TooltipData>(
  ({
    width,
    height,
    margin = { top: 0, right: 0, bottom: 0, left: 0 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
    fullData = [],
  }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
    if (width < 10) return null;

    const [data, setData] = React.useState([...fullData]);
    const [numberOfLines, setNumberOfLines] = React.useState(3);

    const rightAxisWidth = 60;

    // bounds
    const innerWidth =
      width - margin.left - margin.right - (numberOfLines + 1) * rightAxisWidth;
    const innerHeight =
      height - margin.top - margin.bottom - 50 - 2 * areaPadding;

    // scales
    const dateScale = useMemo(
      () =>
        scaleTime({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(data, getDate) as [Date, Date],
        }),
      [data, innerWidth, margin.left]
    );

    const profitCryptoScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, max(data, getProfitCrypto) || 0],
          nice: false,
        }),
      [innerHeight, margin.top, data]
    );

    const lastActionPriceScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, max(data, getLastActionPrice) || 0],
          nice: false,
        }),
      [innerHeight, margin.top, data]
    );

    const closedTransactionsScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, max(data, getClosedTransactions) || 0],
          nice: false,
        }),
      [innerHeight, margin.top, data]
    );

    const currentPortfolioValueScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, max(data, getCurrentPortfolioValue) || 0],
          nice: false,
        }),
      [innerHeight, margin.top, data]
    );

    const currentFiatScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, max(data, getCurrentFiat) || 0],
          nice: false,
        }),
      [innerHeight, margin.top, data]
    );

    const currentCryptoScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top],
          domain: [0, max(data, getCurrentCrypto) || 0],
          nice: false,
        }),
      [innerHeight, margin.top, data]
    );

    // template for graph lines
    const [graphLines, setGraphLines] = React.useState([
      {
        fnc: (d: GraphRecord) => profitCryptoScale(getProfitCrypto(d)),
        scale: profitCryptoScale,
        color: profitCryptoColor,
        get: getProfitCrypto,
        name: "Profit Crypto",
        display: true,
        component: "line",
        colorCheckbox: "#6DFDED",
      },
      {
        fnc: (d: GraphRecord) => lastActionPriceScale(getLastActionPrice(d)),
        scale: lastActionPriceScale,
        color: lastActionPriceColor,
        get: getLastActionPrice,
        name: "Last Action Price",
        display: true,
        component: "line",
        colorCheckbox: "#50C8FC",
      },

      {
        fnc: (d: GraphRecord) =>
          closedTransactionsScale(getClosedTransactions(d)),
        scale: closedTransactionsScale,
        color: closedTransactionsColor,
        get: getClosedTransactions,
        name: "Closed Transactions",
        display: false,
        component: "line",
        colorCheckbox: "#A6F787",
      },
      {
        fnc: (d: GraphRecord) =>
          currentPortfolioValueScale(getCurrentPortfolioValue(d)),
        scale: currentPortfolioValueScale,
        color: currentPortfolioValueColor,
        get: getCurrentPortfolioValue,
        name: "Current Portfolio Value",
        display: true,
        component: "line",
        colorCheckbox: "#FF75CB",
      },

      {
        fnc: (d: GraphRecord) => currentFiatScale(getCurrentFiat(d)),
        scale: currentFiatScale,
        color: currentFiatColor,
        get: getCurrentFiat,
        name: "Current Fiat",
        display: false,
        component: "line",
        colorCheckbox: "#FFA65A",
      },

      {
        fnc: (d: GraphRecord) => currentCryptoScale(getCurrentCrypto(d)),
        scale: currentCryptoScale,
        color: currentCryptoColor,
        get: getCurrentCrypto,
        name: "Current Crypto",
        display: false,
        component: "line",
        colorCheckbox: "#B78CFF",
      },
    ]);

    // tooltip handler
    const handleTooltip = useCallback(
      (
        event:
          | React.TouchEvent<SVGRectElement>
          | React.MouseEvent<SVGRectElement>
      ) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = dateScale.invert(x);
        const index = bisectDate(data, x0, 1);
        const d0 = data[index - 1];
        const d1 = data[index];
        let d = d0;
        if (d1 && getDate(d1)) {
          d =
            x0.valueOf() - getDate(d0).valueOf() >
            getDate(d1).valueOf() - x0.valueOf()
              ? d1
              : d0;
        }
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
        });
      },
      [dateScale, data, showTooltip]
    );

    return (
      <div>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          my={5}
          bg="bg"
          p={4}
          borderRadius="lg"
        >
          {graphLines.map((line) => (
            <Flex>
              <Checkbox
                colorScheme={line.colorCheckbox}
                style={{ color: line.color }}
                mr={2}
                isChecked={line.display}
                onChange={() => {
                  const idx = graphLines.findIndex((l) => l.name === line.name);
                  const newLines = [...graphLines];
                  newLines[idx].display = !newLines[idx].display;
                  setGraphLines(newLines);
                  setNumberOfLines(newLines.filter((l) => l.display).length);
                }}
              />
              <button
                onClick={() => {
                  const idx = graphLines.findIndex((l) => l.name === line.name);
                  const newLines = [...graphLines];
                  newLines[idx].display = !newLines[idx].display;
                  setGraphLines(newLines);
                  setNumberOfLines(newLines.filter((l) => l.display).length);
                }}
                // style={{ color: line.color }}
              >
                <Text color={line.color}>{line.name}</Text>
              </button>
            </Flex>
          ))}
        </Flex>
        <Flex flexDir="row" m={4}>
          <RangeSlider
            max={fullData.length}
            defaultValue={[0, fullData.length]}
            // width={innerWidth}
            onChange={(values) =>
              setData([...fullData.slice(values[0], values[1])])
            }
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
        </Flex>
        <svg
          width={width}
          height={height}
          style={{
            border: "1px solid #6E7191",
            borderRadius: "0.5rem",
            padding: 20,
          }}
        >
          <Group left={margin.left} top={margin.top}>
            <GridRows
              left={margin.left}
              scale={closedTransactionsScale}
              width={innerWidth}
              strokeDasharray="1,3"
              stroke={accentColor}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
            <GridColumns
              top={margin.top}
              scale={dateScale}
              height={innerHeight}
              strokeDasharray="1,3"
              stroke={accentColor}
              strokeOpacity={0}
              pointerEvents="none"
            />
            {graphLines
              .filter((line) => line.display)
              .map((line) => (
                <LinePath<GraphRecord>
                  key={`${line.color}-line`}
                  data={data}
                  x={(d) => dateScale(getDate(d)) ?? 0}
                  y={line.fnc ?? 0}
                  strokeWidth={1}
                  strokeOpacity={1}
                  markerMid="url(#marker-circle)"
                  stroke={line.color}
                  fillOpacity={0}
                />
              ))}
            <AxisBottom
              top={height - 50 - 2 * areaPadding}
              scale={dateScale}
              numTicks={width > 520 ? 10 : 5}
              stroke="white"
              tickStroke="white"
              tickComponent={({ formattedValue, ...tickProps }) => (
                <text
                  {...tickProps}
                  fill="white"
                  fontSize={12}
                  textAnchor="middle"
                >
                  {formattedValue}
                </text>
              )}
            />
            {graphLines
              .filter((line) => line.display)
              .map((line, i) => (
                <AxisRight
                  key={`${line.color}-axisRight`}
                  left={
                    width -
                    margin.right -
                    (numberOfLines + 1) * rightAxisWidth +
                    i * rightAxisWidth
                  }
                  top={margin.top}
                  scale={line.scale}
                  stroke={line.color}
                  tickStroke="white"
                  tickComponent={({ formattedValue, ...tickProps }) => (
                    <text {...tickProps} fill="white" fontSize={12}>
                      {formattedValue}
                    </text>
                  )}
                />
              ))}

            <Bar
              x={margin.left}
              y={margin.top}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
              rx={14}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: margin.top }}
                  to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                  stroke={accentColorDark}
                  strokeWidth={2}
                  pointerEvents="none"
                  strokeDasharray="5,2"
                />
                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop + 1}
                  r={4}
                  fill="black"
                  fillOpacity={0.1}
                  stroke="black"
                  strokeOpacity={0.1}
                  strokeWidth={2}
                  pointerEvents="none"
                />

                {graphLines
                  .filter((line) => line.display)
                  .map((line) => (
                    <circle
                      key={`${line.color}-circle`}
                      cx={tooltipLeft}
                      cy={line.fnc(tooltipData)}
                      r={4}
                      fill={line.color}
                      stroke="white"
                      strokeWidth={2}
                      pointerEvents="none"
                    />
                  ))}
              </g>
            )}
          </Group>
        </svg>
        {/* tooltip for transactions */}
        {tooltipData && (
          <>
            <div>
              <TooltipWithBounds
                top={tooltipTop + 100}
                left={tooltipLeft + 12}
                style={{
                  ...tooltipStyles,
                  background: "#1a202c",
                  color: "black",
                }}
              >
                <Text color="white" fontWeight="bold">
                  {formatDate(getDate(tooltipData))}
                </Text>

                <br />
                <Flex
                  style={{
                    flexDirection: "column",
                  }}
                >
                  {graphLines.find((l) => l.name === "Profit Crypto")
                    ?.display && (
                    <Text color={profitCryptoColor}>
                      Profit Crypto: {tooltipData.ProfitCrypto}
                    </Text>
                  )}
                  {graphLines.find((l) => l.name === "Last Action Price")
                    ?.display && (
                    <Text color={lastActionPriceColor}>
                      Last Action Price: {tooltipData.LastActionPrice}
                    </Text>
                  )}
                  {graphLines.find((l) => l.name === "Closed Transactions")
                    ?.display && (
                    <Text color={closedTransactionsColor}>
                      Closed Transactions: {tooltipData.ClosedTransactions}
                    </Text>
                  )}
                  {graphLines.find((l) => l.name === "Current Portfolio Value")
                    ?.display && (
                    <Text color={currentPortfolioValueColor}>
                      Current Portfolio Value:{" "}
                      {tooltipData.CurrentPortfolioValue}
                    </Text>
                  )}
                  {graphLines.find((l) => l.name === "Current Fiat")
                    ?.display && (
                    <Text color={currentFiatColor}>
                      Current Fiat: {tooltipData.CurrentFiat}
                    </Text>
                  )}
                  {graphLines.find((l) => l.name === "Current Crypto")
                    ?.display && (
                    <Text color={currentCryptoColor}>
                      Current Crypto: {tooltipData.CurrentCrypto}
                    </Text>
                  )}
                </Flex>
              </TooltipWithBounds>
            </div>
          </>
        )}
        {/* tooltip for date */}
        {tooltipData && (
          <div>
            <Tooltip
              top={innerHeight + margin.top + 100}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                minWidth: 72,
                textAlign: "center",
                transform: "translateX(-50%)",
              }}
            >
              {formatDate(getDate(tooltipData))}
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
);
