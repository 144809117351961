import { ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { useEffect } from "react";

import Island from "../components/Island";

const PrivacyPolicyEn = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      <Island>
        <Text fontSize="3xl">Data Processing Policies</Text>
        <Text mb={4} lineHeight="1.8">
          <Text fontSize="xl" my={6}>
            Introductory Information
          </Text>
          Algo Traders Ltd. (limited liability company), ID No.: 19194536,
          registered office: Hoštičky 39, 33901 Klatovy, (hereinafter referred
          to as the &quot;Company&quot;), hereby provides information on the
          method and extent of the personal data processing of its clients
          (hereinafter referred to as the &quot;Client&quot;), including the
          rights of Clients related to the processing of personal data
          (hereinafter referred to as &quot;Policies&quot;), in accordance with
          Act No. 110/2019 Coll., on the processing of personal data and
          Regulation (EU) 2016/679.
          <Text fontSize="xl" my={6}>
            Data Controller and Data Processing Methods
          </Text>
          The Company, as the data controller, collects, retains, and utilizes
          personal data in connection with its business activities. For the
          proper management of these data, these Policies have been established.
          <br /> <br />
          The processing and storage of personal data take place at the
          Company&#39;s registered office and is exclusively performed by the
          Company&#39;s employees unless otherwise stated by these Policies. The
          data processing is both manual and automated in electronic information
          systems, always ensuring high technical, organizational, and personnel
          security as required by relevant legislation.
          <br /> <br />
          If the Client uses the Company&#39;s services, the Company may use the
          Client&#39;s contact details to send commercial messages about the
          services it offers that might interest the Client. The Client can
          unsubscribe from these commercial messages anytime by sending a
          message to info@hodlbot.cz.{" "}
          <Text fontSize="xl" my={6}>
            Purpose of Data Processing
          </Text>
          The Company acts as the data controller in data processing and thus
          determines the purposes and means of personal data processing.
          Personal data is processed either: (i) without the Client&#39;s
          consent based on contract performance, the Company&#39;s legitimate
          interest, or legal obligation fulfillment, or (ii) based on the
          Client&#39;s consent. The Company is authorized to process personal
          data for the following purposes.
          <Text fontSize="xl" my={6}>
            Processing Without Client&#39;s Consent
          </Text>
          Legal obligation fulfillment - obligations according to accounting and
          tax laws, archiving obligation. Legal obligation fulfillment also
          means providing data and information to law enforcement agencies or
          other public authorities in accordance with relevant legal
          regulations. Legal obligation fulfillment further implies the
          Company&#39;s regulatory obligations especially under the following
          regulations:
          <br /> <br />
          Act No. 634/1992 Coll., on consumer protection (this law defines
          consumer rights).
          <Text fontSize="xl" my={6}>
            Processing With Client&#39;s Consent
          </Text>
          Sending and communicating commercial messages and marketing activities
          - offering the Company&#39;s services and promoting them to existing
          and potential clients, transferring data to third parties for display
          of offers on other websites.
          <br /> <br />
          Cookies - cookies are text files containing brief data that can be
          stored on the Client&#39;s device when visiting a website. The
          Company&#39;s website uses cookies for the following purposes: (i)
          functional - necessary for basic website operations, (ii) enhancing
          comfort - improving website functionalities, (iii) social media and
          personalized ad display - allowing connections with social networks
          and tailoring ads based on the Client&#39;s browsing history.
          <Text fontSize="xl" my={6}>
            Scope of Processed Data
          </Text>
          The Company is authorized to process the following personal data,
          obtained from Clients in connection with contract negotiations and
          contract performance, according to the below-mentioned data processing
          purposes. Client&#39;s personal data:
          <UnorderedList ml={12} mt={4}>
            <ListItem>Email </ListItem>
            <ListItem>Contractual relationship fulfillment </ListItem>
            <ListItem>
              Data on the use of the Company&#39;s services, transaction data,
              and communication data.
            </ListItem>
            <ListItem>Client&#39;s website behavior data </ListItem>
            <ListItem>
              Sending and communicating commercial messages (if the Client gave
              consent), Cookies.
            </ListItem>
            <ListItem> Contract and its fulfillment data. </ListItem>
            <ListItem> Access to Personal Data</ListItem>
          </UnorderedList>
          <br /> <br />
          Access to Personal Data Personal data is accessible only to authorized
          employees of the Company or individual data processors, only to the
          extent necessary for the fulfillment of individual processing
          purposes, or based on Client&#39;s consent to personal data
          processing.
          <Text fontSize="xl" my={6}>
            Data Processors
          </Text>
          The Company also authorizes other entities as so- called processors
          for personal data processing. A data processor is an entity that
          processes personal data for the Company for specific purposes, while
          the Company ensures data protection contractually so that technical
          and organizational security of Client&#39;s data is guaranteed and
          cannot be abused. All processors are bound by contractual
          confidentiality and may not use Client data for purposes other than
          those for which the Company makes it accessible.
          <br /> <br />
          With the Client&#39;s consent, the Company also transfers data to
          advertising and social networks for ad display on other websites.
          <br /> <br />
          Data processors are those who provide the Company with server, web,
          cloud, or IT services, or who are the Company&#39;s business partners.
          <br /> <br />
          The Client, as a data subject, has rights related to data processing
          that arise from legal regulations and can be exercised anytime. These
          rights include:
          <br /> <br />
          Right to information and access to personal data: if the Client wants
          to know whether the Company processes personal data, they have the
          right to get information about whether their personal data is
          processed, and if so, they have the right to access these data.
          <br /> <br />
          Right to rectification: if the Client concludes that the Company
          processes inaccurate or incomplete personal data, they have the right
          to request their rectification or completion. The Company will rectify
          or complete the data without undue delay.
          <br /> <br />
          Right to erasure: if the Client requests erasure, the Company will
          delete their personal data if (i) they are no longer needed for the
          purposes for which they were collected or otherwise processed, (ii)
          processing is unlawful, (iii) the Client objects to the processing and
          there are no overriding legitimate grounds for processing.
          <br /> <br />
          Right to Restrict Processing of Personal Data: In the event that the
          Client requests a restriction on processing, the Company will deny
          access to personal data, temporarily remove or store it, or take other
          processing actions that will be necessary for the proper exercise of
          the asserted right.
          <br /> <br />
          Right to Data Portability: If requested by the Client, personal data
          concerning him or her, and which he or she has provided to the
          Company, will be transferred in a structured, commonly used, and
          machine- readable format.
          <br /> <br />
          Right to Object: The right to object to the processing of personal
          data.
          <br /> <br />
          Right to Contact the Supervisory Authority: The Client has the right
          to contact the Office for Personal Data Protection, address: Pplk.
          Sochora 27, 170 00, Prague 7, and file a complaint concerning data
          protection and privacy, and related to the Company&#39;s activities.
          <br /> <br />
          Right to Withdraw Consent: In the event that the Client has provided
          consent to the processing of personal data for purposes requiring
          consent, he or she has the right to withdraw this consent at any time.
          <Text fontSize="xl" my={6}>
            Data Processing Time
          </Text>
          The Company processes personal data for the purpose of fulfilling
          legal obligations for the entire duration of the contract and for 10
          years after its termination. Commercial communications are sent to
          Clients until their subscription is canceled or their consent is
          withdrawn. Further processing of personal data beyond the specified
          periods is carried out by the Company only if necessary for the
          purposes of the legitimate interests of the Company or to fulfill
          obligations arising from legal regulations, for the period strictly
          necessary.
          <br /> <br />
          Personal data that the Company processes on the basis of the
          Client&#39;s consent will be processed until the Client withdraws
          consent. To revoke consent, it is necessary to send an email with the
          relevant request to info@hodlbot.cz
          <Text fontSize="xl" my={6}>
            Data Security
          </Text>
          The Company emphasizes technical and organizational security in
          processing personal data, and for this purpose has taken appropriate
          measures and created a system of internal rules. All personal data in
          electronic form are stored in databases and information systems, to
          which only those persons who need to deal directly with personal data
          for the purposes set out in these Policies have access, and only to
          the extent necessary.
          <Text fontSize="xl" my={6}>
            Final Information
          </Text>
          These Policies are effective from May 1, 2023. Any questions and
          requests related to the processing of personal data can be consulted
          with us via the email address: info@hodlbot.cz
        </Text>
      </Island>
    </>
  );
};

export default PrivacyPolicyEn;
