export type TableRecord = {
  Symbol: string;
  InstanceId: string;
  Buy: number;
  Sell: number;
  TransactionAverage: number;
  ProfitCrypto: number;
  PP1: number;
  PP2: number;
  Flag: Flag[];
  InstanceName: string;
  Status: string;
  Id: string;
  Exchange: string;
};

export type GraphRecord = {
  Date: string;
  InstanceId: string;
  ProfitCrypto: number;
  LastActionPrice: number;
  ClosedTransactions: number;
  CurrentPortfolioValue: number;
  CurrentCrypto: number;
  CurrentFiat: number;
  Flag: Flag[];
};

export type GraphData = {
  [instanceId: string]: { data: GraphRecord[]; flags: Flag[] };
};

export enum Flag {
  SHOW_ALL = "Show All",
  BACKTEST = "BACKTEST",
  ACTIVE = "Active",
  ENDED = "Ended",
  WAITING = "Waiting mode",
}
