import { Text, Tr, Td, Input } from "@chakra-ui/react";
import { Dispatch } from "react";

type Props = {
  item: any;
  setCurrentPrice: Dispatch<string>;
  setCurrentFiat: Dispatch<number>;
  currentPrice: string;
  currentFiat: number;
  disabled?: boolean;
};

const Row = ({
  item,
  setCurrentFiat,
  setCurrentPrice,
  currentFiat,
  currentPrice,
  disabled,
}: Props) => {
  function handlePriceChange() {
    // dispatch('currentPrice', +item.cena)
  }
  function handleFiatChange() {
    // dispatch('currentFiat', +item.fiat)
  }

  function handleKeypress(e: any) {
    if ((e.keyCode < 48 || e.keyCode > 57) && e.key !== ".") {
      e.preventDefault();
      if (e.key === "Enter") {
        e.target.className.includes("bg-fiat")
          ? handleFiatChange()
          : handlePriceChange();
      }
    }
  }

  return (
    <Tr
      fontWeight={item.index === 0 ? "bold" : "normal"}
      bg={item.index % 2 !== 0 ? "island" : "bg"}
    >
      <Td border={0}>
        <Text align="center">{item.index}</Text>
      </Td>
      {item.index === 0 ? (
        <>
          <Td border={0}>
            <Text align="center">INIT PRICE</Text>{" "}
          </Td>
          <Td border={0}>
            <Input
              disabled={disabled}
              bg="#FF9943"
              value={currentPrice ?? 0}
              onChange={(e) => {
                setCurrentPrice(
                 e.target.value.length > 0 ? e.target.value : "0"
                );
              }}
              textAlign="center"
              _disabled={{
                opacity: 1,
                cursor: "not-allowed",
              }}
            />
          </Td>
          <Td border={0}>
            <Input
              disabled={disabled}
              bg="#EAAC30"
              value={currentFiat ?? 0}
              onChange={(e) =>
                setCurrentFiat(
                  parseFloat(e.target.value.length > 0 ? e.target.value : "0")
                )
              }
              textAlign="center"
              _disabled={{
                opacity: 1,
                cursor: "not-allowed",
              }}
            />
          </Td>
        </>
      ) : (
        <>
          <Td border={0}>
            <Text align="center">{item.procenta} %</Text>
          </Td>
          <Td border={0}>
            <Text align="center">{item.cena.toFixed(6)}</Text>
          </Td>
          <Td
            border={0}
            bg={item.fiat < 0 ? "red" : item.index % 2 !== 0 ? "island" : "bg"}
          >
            <Text align="center"> {item.fiat}</Text>
          </Td>
        </>
      )}
      <Td
        border={0}
        bg={
          item.zustatekKrypto < 0
            ? "red"
            : item.index % 2 !== 0
            ? "island"
            : "bg"
        }
      >
        <Text align="center">{item.zustatekKrypto}</Text>
      </Td>
      <Td border={0}>
        <Text align="center">{item.ziskKrypto}</Text>
      </Td>
      <Td border={0}>
        <Text align="center">{item.ziskFiat} $</Text>
      </Td>
    </Tr>
  );
};

export default Row;
