import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { SessionContextProvider } from "./context/SessionContext";
import theme from "./utils/theme";
import CookieBot from "react-cookiebot";
import ReactGA from "react-ga4";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "./output.css";
import TagManager from "react-gtm-module";

const domainGroupId = "2b5be63f-820a-4393-91a2-37f8c89e0af1";

const tagManagerArgs = {
  gtmId: "GTM-PKRX7B4Q",
};

ReactGA.initialize("G-YE27HF7K91");
TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SessionContextProvider>
          <ChakraProvider theme={theme}>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <CookieBot domainGroupId={domainGroupId} />
            <App />
          </ChakraProvider>
        </SessionContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
