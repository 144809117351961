import {
  Flex,
  Button,
  Text,
  Tooltip,
  Spinner,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApiKeys } from "../api/query/useApiKeys";
import { usePairs } from "../api/query/usePairs";
import { useSpotState } from "../api/query/useSpotState";
import Section from "../components/dca/Section";
import FormAutoComplete from "../components/form/FormAutoComplete";
import FormInput from "../components/form/FormInput";
import FormSelect from "../components/form/FormSelect";
import Island from "../components/Island";
import { CloseIcon, InfoIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { RiErrorWarningLine } from "react-icons/ri";
import { ReactComponent as HotelClassIcon } from "../icons/hotel_class.svg";
import { ReactComponent as CalendarIcon } from "../icons/calendar_today.svg";
import { ReactComponent as ScheduleIcon } from "../icons/schedule.svg";
import * as Yup from "yup";
import ReactGA from "react-ga4";

import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddDcaInstance } from "../api/mutations/addDcaInstance";
import { useDcaDetail } from "../api/query/useDcaDetail";
import { useUpdateDcaInstance } from "../api/mutations/useUpdateDca";
import {
  combineDates,
  dcaBudgetDate,
  getDateFromDate,
  getTimeFromDate,
} from "../utils/date";
import { toast } from "react-toastify";

const CalendarCustomInput = forwardRef(({ value, onClick }: any, ref): any => {
  return (
    <button
      style={{
        width: "100%",
        color: "white",
        background: "#14142A",
        border: "1px solid #6E7191",
        borderRadius: "5px",
        height: "40px",
        paddingRight: "16px",
        paddingLeft: "8px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      ref={ref as any}
    >
      <CalendarIcon fill="white" />
      {getDateFromDate(value)}
    </button>
  );
});

const TimeCustomInput = forwardRef(({ value, onClick }: any, ref): any => (
  <button
    style={{
      width: "100%",
      color: "white",
      background: "#14142A",
      border: "1px solid #6E7191",
      borderRadius: "5px",
      height: "40px",
      paddingRight: "16px",
      paddingLeft: "8px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    }}
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    ref={ref as any}
  >
    <ScheduleIcon fill="white" />
    {getTimeFromDate(value)}
  </button>
));

const DcaCreate = () => {
  const { t } = useTranslation();
  const { data: apiKeys, isLoading } = useApiKeys();
  const { mutateAsync: addDcaInstance } = useAddDcaInstance();
  const { mutateAsync: updateDcaInstance } = useUpdateDcaInstance();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const id = useMemo(() => pathname.split("/")[2], [pathname]);

  const requiredMsg = t("register:required");

  const Schema = Yup.object().shape({
    Name: Yup.string().required(requiredMsg),
    ApiId:
      id === "create"
        ? Yup.string().required(requiredMsg)
        : Yup.object().required(requiredMsg),
    PriceUnit: Yup.string().required(requiredMsg),
    Ticker: Yup.string().required(requiredMsg),
    Frequency: Yup.string().required(requiredMsg),
  });

  const validate = (values: any) => {
    try {
      // Validate form values against the schema
      return Schema.validateSync(values, { abortEarly: false });
    } catch (validationError) {
      toast.error("Některá pole nejsou správně vyplněná");
      return validationError;
    }
  };

  const [isSaving, setIsSaving] = useState(false);

  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(new Date());

  const { data, isLoading: dataIsLoading } = useDcaDetail(id);

  const [apiId, setApiId] = useState<string | undefined>(undefined);

  const { data: pairs } = usePairs(apiId);
  const { data: spotState } = useSpotState(apiId);

  const [pair, setPair] = useState<string | undefined>(undefined);

  const currentSpotState = useMemo(
    () => spotState?.Data?.find((i: any) => i.Asset === pair?.split("-")[1]),
    [pair, spotState?.Data]
  );

  const [rules, setRules] = useState<any[]>([
    { id: 0, BelowAverage: "", Value: "", Type: "CONST" },
  ]);

  const selectValues = [
    {
      value: "HOURS",
      label: t("dca:hours"),
      add: 60,
    },
    {
      value: "DAYS",
      label: t("dca:days"),
      add: 60 * 24,
    },
    {
      value: "WEEKS",
      label: t("dca:weeks"),
      add: 60 * 24 * 7,
    },
    {
      value: "MONTHS",
      label: t("dca:months"),
      add: 60 * 24 * 30,
    },

    {
      value: "MINUTES",
      label: t("dca:minutes"),
      add: 1,
    },
  ];

  // Function to add a new rule
  const addRule = () => {
    setRules([
      ...rules,
      { id: rules.length, BelowAverage: "", Value: "", Type: "CONST" },
    ]);
  };

  // Function to remove a rule
  const removeRule = (ruleId: string) => {
    setRules(rules.filter((rule) => rule.id !== ruleId));
  };

  // Function to update a rule
  const updateRule = (ruleId: string, key: string, value: string) => {
    setRules(
      rules.map((rule) =>
        rule.id === ruleId ? { ...rule, [key]: value } : rule
      )
    );
  };

  // Function to handle rule type switch
  const switchRuleType = (ruleId: string, newType: string) => {
    updateRule(ruleId, "Type", newType);
  };

  useEffect(() => {
    if (id === "create") {
      ReactGA.event({ category: "dca", action: "zapoceti_DCA_instance" });
    }
  }, [id]);

  useEffect(() => {
    if (data?.Data?.Config?.Ticker) {
      setPair(data?.Data?.Config?.Ticker);
    }
    if (data?.Data?.Config?.ApiId?.Name) {
      setApiId(
        apiKeys?.Data?.find(
          (i: any) => i.Name === data?.Data?.Config?.ApiId?.Name
        )?.Id ?? ""
      );
    }

    if (data?.Data?.under_average_options) {
      setRules(
        data?.Data?.under_average_options.map((i: any) => ({
          id: i.id,
          BelowAverage: i.below_avg_pct,
          Value: i.value,
          Type: i.type,
        }))
      );
    }

    if (data?.Data?.NextActionTime) {
      setDate(new Date(data?.Data?.NextActionTime));
      setTime(new Date(data?.Data?.NextActionTime));
    }
  }, [apiKeys?.Data, currentSpotState?.Free, data]);

  if (isLoading || dataIsLoading) {
    return (
      <Island p={8} flexDir="column">
        <Spinner />
      </Island>
    );
  }

  return (
    <Formik
      initialValues={{
        ApiId: apiKeys?.Data?.find(
          (i: any) => i.Name === data?.Data?.Config?.ApiId?.Name
        ),
        Name: data?.Data?.Config?.Name ?? "",
        PriceUnit: data?.Data?.Config?.PriceUnit ?? "",
        Ticker: data?.Data?.Config?.Ticker ?? "",
        Frequency: data?.Data?.Config?.Frequency ?? "",
        TimeUnit: data?.Data?.Config?.TimeUnit ?? "HOURS",
        NextActionTime: data?.Data?.NextActionTime ?? "",
        SpendLimit: data?.Data?.spend_limit?.limit ?? "",
        PriceLimit_Limit: data?.Data?.price_limit?.limit ?? "",
        PriceLimit_Ratio: data?.Data?.price_limit?.ratio ?? "",
      }}
      validationSchema={Schema}
      onSubmit={async (values) => {
        setIsSaving(true);
        const r = rules
          .map((rule) => ({
            id: rule.id,
            below_avg_pct: parseFloat(rule.BelowAverage),
            value: parseFloat(rule.Value),
            type: rule.Type,
          }))
          .filter(
            (i) => isNaN(i.below_avg_pct) === false && isNaN(i.value) === false
          );

        try {
          if (data?.Data) {
            const payload = {
              instance_id: data?.Data?.Config?.InstanceId,
              state_id: data?.Data?.StateId,
              api_id: values.ApiId.Id,
              name: values.Name,
              price_unit: parseFloat(values.PriceUnit),
              ticker: values.Ticker,
              frequency: parseFloat(values.Frequency),
              time_unit: values.TimeUnit,
              next_action_time: combineDates(date, time),
              spend_limit:
                values.SpendLimit === ""
                  ? null
                  : {
                      limit: values.SpendLimit
                        ? parseFloat(values.SpendLimit)
                        : null,
                    },
              price_limit:
                values.PriceLimit_Limit === "" || values.PriceLimit_Ratio === ""
                  ? null
                  : {
                      limit: values.PriceLimit_Limit
                        ? parseFloat(values.PriceLimit_Limit)
                        : null,
                      ratio: values.PriceLimit_Ratio
                        ? parseFloat(values.PriceLimit_Ratio)
                        : null,
                    },
              under_average_options: r.length > 0 ? r : null,
            };

            await updateDcaInstance({ body: payload });
            setIsSaving(false);
            navigate(`/dca/${data?.Data?.StateId}`);
          } else {
            const payload = {
              api_id: values.ApiId,
              name: values.Name,
              price_unit: parseFloat(values.PriceUnit),
              ticker: values.Ticker,
              frequency: parseFloat(values.Frequency),
              time_unit: values.TimeUnit,
              next_action_time: combineDates(date, time),
              spend_limit:
                values.SpendLimit === ""
                  ? null
                  : {
                      limit: values.SpendLimit
                        ? parseFloat(values.SpendLimit)
                        : null,
                    },
              price_limit:
                values.PriceLimit_Limit === "" || values.PriceLimit_Ratio === ""
                  ? null
                  : {
                      limit: values.PriceLimit_Limit
                        ? parseFloat(values.PriceLimit_Limit)
                        : null,
                      ratio: values.PriceLimit_Ratio
                        ? parseFloat(values.PriceLimit_Ratio)
                        : null,
                    },
              under_average_options: r.length > 0 ? r : null,
            };

            await addDcaInstance({ body: payload });
            ReactGA.event({
              category: "dca",
              action: "dokonceni_DCA_instance",
            });
            setIsSaving(false);
            navigate(`/dca`);
          }
        } catch (e) {
          setIsSaving(false);
          console.log(e);
          toast.error(
            "Nepodařilo se založit DCA. Zkontrolujte prosím zadané údaje."
          );
        }
      }}
    >
      {({ dirty, ...form }) => {
        console.log(form?.values);
        return (
          <Form>
            {dirty && (
              <Flex
                m={{ base: 0, md: 4, lg: 4 }}
                bg="#CBFFAE"
                position="sticky"
                p={2}
                color="#067306"
                top={16}
                zIndex={100}
                px={8}
                alignItems="center"
                gap={1}
              >
                <RiErrorWarningLine fill="#067306" />
                <Text> Máte neuložené změny</Text>
              </Flex>
            )}

            <Island p={8} flexDir="column">
              <Flex flexDir="column" gap={4} w="full">
                <Text fontSize={32}>
                  {t(
                    data?.Data ? "dca_create:title_update" : "dca_create:title"
                  )}
                </Text>
                <Text fontSize={16}>{t("dca_create:subtitle")}</Text>
              </Flex>

              <Section mt={10}>
                <Text fontSize={16} display="flex" alignItems="center">
                  {t("dca_create:name_title")}{" "}
                  <Tooltip label={t("dca_create:name_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>
                <FormInput
                  name="Name"
                  {...form}
                  width="full"
                  placeholder={t("dca_create:name_placeholder") ?? ""}
                  className="!border-[#6E7191]"
                />
              </Section>

              <Section mt={6} disabled={data?.Data}>
                <Text display="flex" alignItems="center">
                  {t("dca_create:api_key_title")}

                  <Tooltip label={t("dca_create:api_key_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>

                {apiKeys.Data === null && (
                  <Section
                    color="#FF9943"
                    bg="#994600"
                    borderColor="#FF9943"
                    borderWidth={1}
                  >
                    <Flex w="full" justifyContent="space-between">
                      <Text>{t("dca_create:no_api_keys")}</Text>
                      <Text
                        cursor="pointer"
                        onClick={() => navigate("/api_keys")}
                      >
                        {t("dca_create:no_api_keys_link")}
                      </Text>
                    </Flex>
                  </Section>
                )}

                {/* <FormSelect
                  name="ApiId"
                  placeholder={t("dca_create:api_key_placeholder")}
                  options={apiKeys.Data ?? []}
                  optionLabel={(option) => `${option.Name} (${option.Key_S})`}
                  optionValue={(option) => option.Id}
                  onSelect={(val) => setApiId(val)}
                  {...form}
                /> */}

                <FormAutoComplete
                  placeholder={t("dca_create:api_key_placeholder")}
                  placeholderDisabled=""
                  name="ApiId"
                  options={apiKeys.Data ?? []}
                  optionLabel={(option) => `${option?.Name} (${option?.Key_S})`}
                  optionValue={(option) => option?.Id}
                  onSelect={(val) => setApiId(val)}
                  defaultValue={form.values.ApiId}
                  {...form}
                />
              </Section>

              <Section
                mt={6}
                disabled={
                  form.values.ApiId === "" ||
                  form.values.ApiId === undefined ||
                  data?.Data
                }
              >
                <Text display="flex" alignItems="center">
                  {t("dca_create:name_pair")}
                  <Tooltip label={t("dca_create:pair_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>

                {data?.Data ? (
                  <FormInput
                    name="Ticker"
                    {...form}
                    width="full"
                    className="!border-[#6E7191]"
                  />
                ) : (
                  <FormAutoComplete
                    disabled={form.values.ApiId === ""}
                    placeholder={t("dca_create:pair_placeholder")}
                    placeholderDisabled={t(
                      "dca_create:pair_disabled_placeholder"
                    )}
                    name="Ticker"
                    options={pairs?.Data ?? []}
                    optionLabel={(option) => option?.Pair}
                    optionValue={(option) => option?.Pair}
                    onSelect={(val) => setPair(val)}
                    {...form}
                  />
                )}
              </Section>

              <Section mt={6} disabled={form.values.Ticker === ""}>
                <Text display="flex" alignItems="center">
                  {t("dca_create:name_amount")}
                  <Tooltip label={t("dca_create:amount_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>
                <Flex gap={2} alignItems="center" className="w-full">
                  <Text whiteSpace="nowrap" fontSize={14}>
                    {t("dca_create:amount_text_1")}
                  </Text>
                  <FormInput
                    name="PriceUnit"
                    {...form}
                    className="!border-[#6E7191]"
                    extension={pair?.split("-")[1] ?? ""}
                    w="full"
                  />
                </Flex>

                <Flex gap={2} alignItems="center" fontSize={14}>
                  <Text whiteSpace="nowrap">
                    {t("dca_create:amount_text_2")}{" "}
                    {pair ? currentSpotState?.Free ?? 0 : "-- // --"}
                  </Text>
                  <Text whiteSpace="nowrap">
                    {t("dca_create:amount_text_3", {
                      amount:
                        form.values.PriceUnit !== "" && pair
                          ? Math.trunc(
                              currentSpotState?.Free /
                                (parseFloat(form.values.PriceUnit) ?? 0) ?? 0
                            ) ?? 0
                          : "-- // --",
                    })}{" "}
                  </Text>
                </Flex>
              </Section>

              <Section mt={6} disabled={form.values.Ticker === ""}>
                <Text display="flex" alignItems="center">
                  {t("dca_create:name_interval")}
                  <Tooltip label={t("dca_create:interval_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>
                <Flex gap={2} alignItems="center">
                  <Text whiteSpace="nowrap" fontSize={14}>
                    {t("dca_create:interval_text")}
                  </Text>

                  <FormInput
                    name="Frequency"
                    w="min"
                    className="!border-[#6E7191]"
                    {...form}
                  />
                  <FormSelect
                    name="TimeUnit"
                    placeholder=""
                    options={selectValues ?? []}
                    optionLabel={(option) => option.label}
                    optionValue={(option) => option.value}
                    {...form}
                  />
                </Flex>
              </Section>

              <Section mt={6} disabled={form.values.Ticker === ""}>
                <Text display="flex" alignItems="center">
                  {t("dca_create:name_first_buy")}
                  <Tooltip label={t("dca_create:first_buy_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>
                <Flex w="full">
                  <DatePicker
                    selected={date}
                    onChange={(v: any) => setDate(v)}
                    dateFormat="Pp"
                    inputStyle={{
                      color: "black",
                    }}
                    customInput={<CalendarCustomInput />}
                    wrapperClassName="datepicker"
                    onClick={(e: any) => e.preventDefault()}
                  />

                  <div className="w-10" />

                  <DatePicker
                    selected={time}
                    onChange={(v: any) => setTime(v)}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="Pp"
                    inputStyle={{
                      color: "black",
                    }}
                    customInput={<TimeCustomInput />}
                    wrapperClassName="datepicker"
                    onClick={(e: any) => e.preventDefault()}
                  />
                </Flex>

                <Flex gap={2} alignItems="center" fontSize={14}>
                  <Text whiteSpace="nowrap">
                    {t("dca_create:first_buy_text")}{" "}
                    {dcaBudgetDate(
                      (pair
                        ? Math.trunc(
                            currentSpotState?.Free /
                              (parseFloat(form.values.PriceUnit) ?? 1) ?? 1
                          ) ?? 1
                        : 1) *
                        form.values.Frequency *
                        (selectValues?.find(
                          (i) => i.value === form?.values?.TimeUnit
                        )?.add ?? 1)
                    )}{" "}
                  </Text>
                </Flex>
              </Section>

              <Section mt={6} disabled={form.values.Ticker === ""}>
                <Text display="flex" alignItems="center">
                  {t("dca_create:capital_restriction")}

                  <Text ml={2} fontSize={12} color="#A0A3BD">
                    ({t("dca_create:optional")})
                  </Text>

                  <Tooltip label={t("dca_create:capital_restriction_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>
                <Flex w="full">
                  <Wrap
                    gap={2}
                    alignItems="center"
                    flexDir="row"
                    flexWrap="wrap"
                  >
                    <WrapItem alignItems="center">
                      <Text whiteSpace="nowrap" fontSize={14}>
                        {t("dca_create:capital_restriction_text")}
                      </Text>
                    </WrapItem>

                    <WrapItem alignItems="center">
                      <FormInput
                        name="SpendLimit"
                        w="min"
                        {...form}
                        className="!border-[#6E7191]"
                        extension={pair?.split("-")[1] ?? ""}
                      />
                    </WrapItem>

                    <WrapItem alignItems="center">
                      <Text whiteSpace="nowrap" fontSize={14}>
                        {t("dca_create:capital_restriction_text_2")}
                      </Text>
                    </WrapItem>
                  </Wrap>
                </Flex>
              </Section>

              <Section mt={6} disabled={form.values.Ticker === ""}>
                <Flex gap={2} alignItems="center">
                  <Text display="flex" alignItems="center">
                    {t("dca_create:name_special")}

                    <Text ml={2} fontSize={12} color="#A0A3BD">
                      ({t("dca_create:optional")})
                    </Text>

                    <Tooltip label={t("dca_create:special_info")}>
                      <InfoIcon fill="#50C8FC" w={8} />
                    </Tooltip>
                  </Text>
                </Flex>

                <Flex gap={2} alignItems="center" flexDir="column">
                  {rules.map((rule, i) => (
                    <Wrap gap={2}>
                      <WrapItem alignItems="center">
                        <Text
                          whiteSpace="nowrap"
                          fontSize={14}
                          border="1px solid 2hite"
                        >
                          {t("dca_create:special_text_1")}
                        </Text>
                      </WrapItem>
                      <WrapItem alignItems="center">
                        <FormInput
                          name={`condition_value_${rule.id}`}
                          {...form}
                          onChange={(e) =>
                            updateRule(rule.id, "BelowAverage", e.target.value)
                          }
                          className="!border-[#6E7191]"
                          value={rule.BelowAverage}
                          extension="%"
                        />
                      </WrapItem>
                      <WrapItem alignItems="center">
                        <Text whiteSpace="nowrap" fontSize={14}>
                          {t("dca_create:special_text_2")}
                        </Text>
                      </WrapItem>

                      <WrapItem alignItems="center">
                        <FormInput
                          name={`add_value_${rule.id}`}
                          {...form}
                          onChange={(e) =>
                            updateRule(rule.id, "Value", e.target.value)
                          }
                          className="!border-[#6E7191]"
                          value={rule.Value}
                          extension={
                            rule.Type === "CONST"
                              ? pair?.split("-")[1] ?? ""
                              : "%"
                          }
                        />
                      </WrapItem>
                      <WrapItem alignItems="center">
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Flex
                            border="1px solid #50C8FC"
                            mx={4}
                            borderRadius="lg"
                            cursor="pointer"
                          >
                            <Flex
                              m={0}
                              p={2}
                              onClick={() => switchRuleType(rule.id, "CONST")}
                              bg={rule.Type === "CONST" ? "#50C8FC" : "none"}
                              color={
                                rule.Type === "CONST" ? "#14142A" : "white"
                              }
                              borderRadius="md"
                              alignItems="center"
                            >
                              <Text ml={2} fontWeight={300}>
                                {t("dca_create:amount")}
                              </Text>
                            </Flex>
                            <Flex
                              m={0}
                              p={2}
                              onClick={() => switchRuleType(rule.id, "RATIO")}
                              bg={rule.Type === "RATIO" ? "#50C8FC" : "none"}
                              color={
                                rule.Type === "RATIO" ? "#14142A" : "white"
                              }
                              borderRadius="md"
                              alignItems="center"
                            >
                              <Text ml={2}>{t("dca_create:ratio")}</Text>
                            </Flex>
                          </Flex>

                          <CloseIcon
                            className="cursor-pointer"
                            onClick={() => removeRule(rule.id)}
                          />
                        </Flex>
                      </WrapItem>
                    </Wrap>
                  ))}
                </Flex>
                <Button
                  bg="bg"
                  color="brand"
                  fontSize={14}
                  w="full"
                  borderWidth={1}
                  borderColor="#6E7191"
                  _hover={{ bg: "brand", color: "island" }}
                  onClick={addRule}
                >
                  {t("dca_create:special_button")}
                </Button>
              </Section>

              <Section mt={6} disabled={form.values.Ticker === ""}>
                <Text display="flex" alignItems="center">
                  {t("dca_create:name_price_border")}

                  <Text ml={2} fontSize={12} color="#A0A3BD">
                    ({t("dca_create:optional")})
                  </Text>

                  <Tooltip label={t("dca_create:price_border_info")}>
                    <InfoIcon fill="#50C8FC" w={8} />
                  </Tooltip>
                </Text>
                <Flex w="full">
                  <Wrap
                    gap={2}
                    alignItems="center"
                    flexDir="row"
                    flexWrap="wrap"
                  >
                    <WrapItem alignItems="center">
                      <Text whiteSpace="nowrap" fontSize={14}>
                        {t("dca_create:price_border_text_1", {
                          unit: pair?.split("-")[0] ?? "-",
                        })}
                      </Text>
                    </WrapItem>

                    <WrapItem alignItems="center">
                      <FormInput
                        name="PriceLimit_Limit"
                        w="min"
                        {...form}
                        className="!border-[#6E7191]"
                        extension={pair?.split("-")[1] ?? "-"}
                      />
                    </WrapItem>

                    <WrapItem alignItems="center">
                      <Text whiteSpace="nowrap" fontSize={14}>
                        {t("dca_create:price_border_text_2")}
                      </Text>
                    </WrapItem>

                    <WrapItem alignItems="center">
                      <FormInput
                        name="PriceLimit_Ratio"
                        w="min"
                        {...form}
                        className="!border-[#6E7191]"
                      />
                    </WrapItem>

                    <WrapItem alignItems="center">
                      <Text whiteSpace="nowrap" fontSize={14}>
                        {t("dca_create:price_border_text_3")}
                      </Text>
                    </WrapItem>
                  </Wrap>
                </Flex>

                {form.values.PriceLimit_Limit !== "" &&
                  form.values.PriceLimit_Ratio !== "" && (
                    <Text>
                      {t("dca_create:price_border_special_info", {
                        limit: form.values.PriceLimit_Limit,
                        unit: pair?.split("-")[1] ?? "-",
                        ratio:
                          form.values.PriceLimit_Ratio * form.values.PriceUnit,
                      })}
                    </Text>
                  )}
              </Section>

              <Section mt={6} bg="#550084" opacity={0.5}>
                <Flex gap={2} alignItems="center">
                  <Flex
                    className="special_div"
                    p={2}
                    py={1}
                    alignItems="center"
                    gap={2}
                  >
                    <HotelClassIcon /> {t("dca_create:soon")}
                  </Flex>
                  <Text>{t("dca_create:dynamic_strategy")}</Text>
                </Flex>
                <Flex w="full">
                  <Text>
                    {t("dca_create:more_info")}
                    <a
                      href="https://www.lookintobitcoin.com/charts/relative-unrealized-profit--loss/"
                      className=" text-[#50C8FC] border-[#50C8FC] p-1 rounded-md"
                    >
                      {t("dca_create:here")}
                    </a>
                  </Text>
                </Flex>
              </Section>

              <Section mt={6} bg="#550084" disabled>
                <Flex gap={2} alignItems="center">
                  <Flex
                    className="special_div"
                    p={2}
                    py={1}
                    alignItems="center"
                    gap={2}
                  >
                    <HotelClassIcon /> {t("dca_create:soon")}
                  </Flex>
                  {t("dca_create:dynamic_strategy_under_name")}
                </Flex>

                <Flex gap={2} alignItems="center" flexDir="column">
                  {rules.map((rule, i) => (
                    <Wrap gap={2}>
                      <WrapItem alignItems="center">
                        <Text
                          whiteSpace="nowrap"
                          fontSize={14}
                          border="1px solid 2hite"
                        >
                          {t("dca_create:special_text_1")}
                        </Text>
                      </WrapItem>
                      <WrapItem alignItems="center">
                        <FormInput
                          name={`condition_value_${rule.id}`}
                          {...form}
                          onChange={(e) =>
                            updateRule(rule.id, "BelowAverage", e.target.value)
                          }
                          className="!border-[#6E7191]"
                          value={rule.BelowAverage}
                          extension="%"
                        />
                      </WrapItem>
                      <WrapItem alignItems="center">
                        <Text whiteSpace="nowrap" fontSize={14}>
                          {t("dca_create:special_text_2")}
                        </Text>
                      </WrapItem>

                      <WrapItem alignItems="center">
                        <FormInput
                          name={`add_value_${rule.id}`}
                          {...form}
                          onChange={(e) =>
                            updateRule(rule.id, "Value", e.target.value)
                          }
                          className="!border-[#6E7191]"
                          value={rule.Value}
                          extension={
                            rule.Type === "CONST"
                              ? pair?.split("-")[1] ?? ""
                              : "%"
                          }
                        />
                      </WrapItem>
                      <WrapItem alignItems="center">
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Flex
                            border="1px solid #50C8FC"
                            mx={4}
                            borderRadius="lg"
                            cursor="pointer"
                          >
                            <Flex
                              m={0}
                              p={2}
                              onClick={() => switchRuleType(rule.id, "CONST")}
                              bg={rule.Type === "CONST" ? "#50C8FC" : "none"}
                              color={
                                rule.Type === "CONST" ? "#14142A" : "white"
                              }
                              borderRadius="md"
                              alignItems="center"
                            >
                              <Text ml={2} fontWeight={300}>
                                {t("dca_create:amount")}
                              </Text>
                            </Flex>
                            <Flex
                              m={0}
                              p={2}
                              onClick={() => switchRuleType(rule.id, "RATIO")}
                              bg={rule.Type === "RATIO" ? "#50C8FC" : "none"}
                              color={
                                rule.Type === "RATIO" ? "#14142A" : "white"
                              }
                              borderRadius="md"
                              alignItems="center"
                            >
                              <Text ml={2}>{t("dca_create:ratio")}</Text>
                            </Flex>
                          </Flex>

                          <CloseIcon
                            className="cursor-pointer"
                            onClick={() => removeRule(rule.id)}
                          />
                        </Flex>
                      </WrapItem>
                    </Wrap>
                  ))}
                </Flex>
                <Button
                  bg="bg"
                  color="brand"
                  fontSize={14}
                  w="full"
                  borderWidth={1}
                  borderColor="#6E7191"
                  _hover={{ bg: "brand", color: "island" }}
                  onClick={addRule}
                >
                  {t("dca_create:special_button")}
                </Button>
              </Section>

              <Button
                mt={8}
                bg="brand"
                color="island"
                fontSize={14}
                type="submit"
                onClick={() => {
                  const err = validate(form.values);
                  if (err === undefined) {
                    form.submitForm();
                  }
                }}
              >
                {isSaving ? (
                  <Spinner />
                ) : (
                  t(data?.Data ? "dca_create:update" : "dca_create:submit")
                )}
              </Button>
            </Island>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DcaCreate;
