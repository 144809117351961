import React, { ReactElement, ReactNode, useContext, useEffect } from "react";
import {
  Icon,
  Button,
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Slide,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { FiMenu, FiLogIn } from "react-icons/fi";
import { ReactText } from "react";
import { SessionCtx } from "../context/SessionContext";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BsFillKeyFill, BsCashCoin, BsQuestionCircle } from "react-icons/bs";

import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { ReactComponent as GradeIcon } from "../icons/grade.svg";
import { ReactComponent as ConversionPath } from "../icons/conversion_path.svg";

import { ReactComponent as CalculatorIcon } from "../icons/calculate.svg";
import { ReactComponent as RobotIcon } from "../icons/robot.svg";
import { ReactComponent as AnalyticsIcon } from "../icons/analytics.svg";

import { ReactComponent as GavelIcon } from "../icons/gavel.svg";
import { ReactComponent as CallIcon } from "../icons/call.svg";

import { ReactComponent as AccountIcon } from "../icons/account_circle.svg";

import "./logo.css";
import LanguageSwitch from "./language-switch";

interface LinkItemProps {
  id?: string;
  name: string;
  items?: {
    name: string;
    iconType?: string;
    icon?: ReactElement<any, any>;
    href: string;
  }[];
}

export default function SidebarWithHeader({
  children,
}: {
  children: ReactNode;
}) {
  const storageMenuOpen =
    localStorage.getItem("hodlbot_menu_open") === null
      ? true
      : localStorage.getItem("hodlbot_menu_open") === "true";

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMenuOpen, setIsMenuOpen] = React.useState(storageMenuOpen);

  const location = useLocation();

  const showNav = !(
    location.pathname === "/signin" || location.pathname === "/signup"
  );

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    localStorage.setItem("hodlbot_menu_open", String(!isMenuOpen));
  };

  return (
    <>
      <Box minH="100%">
        {showNav ? (
          <>
            <Slide
              direction="left"
              in={isMenuOpen}
              style={{
                zIndex: 0,
                padding: 16,
                width: 300,
                transition: "0.1s",
              }}
            >
              <SidebarContent
                onClose={() => onClose}
                display={{ base: "none", md: isMenuOpen ? "block" : "none" }}
                m={4}
                mt={20}
              />
            </Slide>
            <Drawer
              autoFocus={false}
              isOpen={isOpen}
              placement="left"
              onClose={onClose}
              returnFocusOnClose={false}
              size="full"
            >
              <DrawerContent overflowY="scroll" bg="island" pt={16}>
                <SidebarContent onClose={onClose} />
              </DrawerContent>
            </Drawer>
            <MobileNav
              onOpen={onOpen}
              isOpen={isOpen}
              onClose={onClose}
              toggleMenu={toggleMenu}
            />
            <Flex
              transition="0.4s ease"
              ml={{ base: 0, md: isMenuOpen ? 64 : 0 }}
              justifyContent="center"
            >
              <Flex flexDir="column" maxW={1200} p={4} pt={20} w="full">
                {children}
              </Flex>
            </Flex>
          </>
        ) : (
          children
        )}
      </Box>
    </>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { t } = useTranslation();
  const { user } = useContext(SessionCtx);
  const [articles, setArticles] = React.useState<any[]>([]);

  const location = useLocation();

  useEffect(() => {
    (async () => {
      const res = await fetch(
        "https://directus.botcalculator.com/items/aa_articles"
      );
      const json = await res.json();
      console.log(json.data);
      setArticles(json.data);
    })();
  }, []);

  const LinkItems: Array<LinkItemProps | null> = [
    {
      name: user ? t("menu:public_section") : "",
      id: user ? "applications" : undefined,
      items: [
        {
          name: t("menu:introduction"),
          href: "/#introduction",
          icon: <HomeIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:sample_portfolio"),
          href: "/#portfolio",
          icon: <GradeIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:more_informations"),
          href: "/#more_info",
          icon: <InfoIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:pricing"),
          href: "/#pricing",
          icon: <Icon as={BsCashCoin} fontSize="2xl" />,
        },
        {
          name: t("menu:they_mentioned_us"),
          href: "/#reviews",
          icon: <GradeIcon fill="#FFFFFF" />,
        },
      ],
    },
    {
      name: "",
      items: [
        {
          name: t("menu:dcaxbotxhodl"),
          href: "/hodl_x_hodlBot",
          icon: <RobotIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:calculator"),
          href: "/calculator",
          icon: <CalculatorIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:statistics"),
          href: "/statistics",
          icon: <AnalyticsIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:backtest"),
          href: "/backtest",
          icon: <AnalyticsIcon fill="#FFFFFF" />,
        },
      ],
    },

    user && {
      name: t("menu:my_applications"),
      items: [
        {
          name: t("menu:profile"),
          href: "/profile",
          icon: <AccountIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:dca"),
          href: "/dca",
          icon: <AccountIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:my_statistics"),
          href: "/my_statistics",
          icon: <AnalyticsIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:api_keys"),
          href: "/api_keys",
          icon: <Icon as={BsFillKeyFill} fontSize="2xl" />,
        },
        {
          name: "Affiliate",
          href: "/affiliate",
          icon: <AnalyticsIcon fill="#FFFFFF" />,
        },
      ],
    },
    articles.length > 0
      ? {
          name: "FAQ",
          id: "applications",
          items: articles.filter((article) => article.active).map((article) => ({
            name: article.title,
            href: `/articles/${article.id}`,
            icon: <Icon as={BsQuestionCircle} fontSize="2xl" />,
          })),
        }
      : null,
    {
      name: t("menu:service"),
      items: [
        {
          name: t("menu:general_terms"),
          href: "/general_terms",
          icon: <GavelIcon fill="#FFFFFF" />,
        },
        {
          name: t("menu:contacts"),
          href: "/contacts",
          icon: <CallIcon fill="#FFFFFF" />,
        },
      ],
    },
  ].filter((section) => section !== null);

  return (
    <Box
      bg="island"
      w={{ base: "full", md: 60 }}
      height={user ? "669px" : "615px"}
      borderRadius="10px"
      {...rest}
    >
      <Flex
        flexDir="column"
        justifyContent="space-between"
        height="100%"
        px={2}
        overflowY="auto"
        css={{
          "::-webkit-scrollbar": {
            width: "12px",
          },
          "::-webkit-scrollbar-track": {
            "background-color": "#262338",
            border: "1px solid #14142A",
            "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
          },
          "::-webkit-scrollbar-thumb": {
            "background-color": "rgba(0, 0, 0, .3)",
          },
        }}
      >
        <Box>
          {LinkItems.map(
            (section, i) =>
              section && (
                <>
                  {section.id === "applications" ? (
                    <>
                      <Accordion w="full" allowMultiple>
                        <AccordionItem border="none">
                          <AccordionButton color="white">
                            <Box as="span" flex="1" textAlign="left">
                              <Text my={2} color="white" fontSize={14}>
                                {section.name}
                              </Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>

                          <AccordionPanel p={0}>
                            <Divider />
                            {section.items?.map((link) =>
                              link.icon ? (
                                <NavItem
                                  as={RouterLink}
                                  to={link.href}
                                  key={link.name}
                                  icon={link.icon}
                                  onClose={onClose}
                                  active={
                                    location.pathname + location.hash ===
                                    link.href
                                  }
                                >
                                  {link.name}
                                </NavItem>
                              ) : (
                                <NavItem
                                  onClose={onClose}
                                  as={RouterLink}
                                  to={link.href}
                                  key={link.name}
                                >
                                  {link.name}
                                </NavItem>
                              )
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>

                      {i < 4 && <Divider />}
                    </>
                  ) : (
                    <>
                      <Text mx={3} my={4} color="white" fontSize={14}>
                        {section.name}
                      </Text>
                      {section.items?.map((link) =>
                        link.icon ? (
                          <NavItem
                            as={RouterLink}
                            to={link.href}
                            key={link.name}
                            icon={link.icon}
                            active={
                              location.pathname + location.hash === link.href
                            }
                            onClose={onClose}
                          >
                            {link.name}
                          </NavItem>
                        ) : (
                          <NavItem
                            as={RouterLink}
                            to={link.href}
                            key={link.name}
                            onClose={onClose}
                          >
                            {link.name}
                          </NavItem>
                        )
                      )}
                      {i < 3 && <Divider />}
                    </>
                  )}
                </>
              )
          )}
        </Box>
      </Flex>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon?: ReactElement<any, any>;
  children: ReactText;
  to: string;
  active?: boolean;
  onClose: () => void;
}
const NavItem = ({
  icon,
  children,
  active,
  onClose,
  ...rest
}: NavItemProps) => {
  return (
    <Box my={2}>
      <Link
        href="#"
        style={{ textDecoration: "none" }}
        _focus={{ boxShadow: "none" }}
        onClick={onClose}
      >
        <Flex
          align="center"
          py="2"
          borderRadius="full"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "#005BD4",
            color: "white",
          }}
          bg={active ? "#005BD4" : "transparent"}
          color={active ? "white" : "white"}
          {...rest}
          px={3}
        >
          {icon}

          <Text color="white" fontSize={14} pl={2}>
            {children}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  toggleMenu: () => void;
}

const MobileNav = ({
  onOpen,
  onClose,
  isOpen,
  toggleMenu,
  ...rest
}: MobileProps) => {
  const { user, logout } = useContext(SessionCtx);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Flex
      px={{ base: 4, md: 4 }}
      height="16"
      alignItems="center"
      bg="island"
      justifyContent="space-between"
      w="full"
      position="fixed"
      zIndex="99999999"
      animation="fadeInDown"
      transition="all .25s ease"
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={() => (isOpen ? onClose() : onOpen())}
        variant=""
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Box display={{ base: "none", md: "flex" }}>
        <IconButton
          onClick={() => toggleMenu()}
          variant=""
          aria-label="open menu"
          icon={<FiMenu />}
        />
      </Box>

      <Flex
        alignItems="center"
        onClick={() => navigate("/")}
        width={150}
        cursor="pointer"
        // className="logo"
      >
        <Flex overflow="hidden">
          <img
            src="HodlbotLogo.svg"
            alt="Hodl Logo"
            className="logo"
            style={{
              width: 38,
              height: 34,
            }}
          />
        </Flex>
        <Text
          ml={2}
          fontSize="xl"
          variant="brand"
          textTransform="uppercase"
          fontWeight={600}
        >
          Hodlbot
        </Text>
      </Flex>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <LanguageSwitch />
          {user ? (
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Avatar
                    name={`${user?.first_name} ${user?.last_name}`}
                    src={user?.avatar}
                    bg="brand"
                    size="sm"
                    style={{
                      color: "white",
                    }}
                  />
                </HStack>
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => logout()}>
                  {" "}
                  {t("menu:logout")}
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <Button
              leftIcon={
                <Icon
                  fontSize="16"
                  _groupHover={{
                    color: "white",
                  }}
                  as={FiLogIn}
                />
              }
              as={RouterLink}
              bg="brand"
              color="island"
              to="/signin"
              fontSize={14}
              fontWeight={500}
              borderRadius="full"
            >
              {t("menu:login")}
            </Button>
          )}
        </Flex>
      </HStack>
    </Flex>
  );
};
